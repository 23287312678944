<template>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen>
        <v-card>
            <v-toolbar>
                <v-toolbar-title
                    >{{ roleId ? "Update Role" : "Create Role" }}
                    {{ roleId && roleData ? ` - ${roleData.name}` : "" }}
                    {{
                        roleData && !roleId
                            ? ` - ( copy from ${roleData.name} )`
                            : ""
                    }}</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon="mdi-close" @click="$emit('close')"></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-list lines="two" subheader>
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-cog</v-icon>
                    General Information</v-list-subheader
                >
                <v-list-item>
                    <v-col>
                        <v-text-field
                            v-model="name"
                            label="Name"
                            variant="outlined"
                            density="compact"
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-tune</v-icon>
                    Permissions</v-list-subheader
                >
                <v-list-item>
                    <v-tabs v-model="tab" bg-color="primary">
                        <!-- {{ hasInvalidPercentages }} -->
                        <v-tab
                            v-for="(tabName, i) in Object.keys(permissions)"
                            :key="`tab-${i}`"
                            :text="`${tabName}`"
                            :value="`tab-${i}`"
                        ></v-tab>
                    </v-tabs>
                    <v-tabs-window v-model="tab">
                        <v-tabs-window-item
                            v-for="(tabName, j) in Object.keys(permissions)"
                            :key="`tab-content-${j}`"
                            :value="`tab-${j}`"
                        >
                            <!-- <v-radio-group
                                v-model="selectedPermissions"
                                inline
                                label="Permissions"
                                hide-details
                            >
                                <v-radio
                                    v-for="permission in permissions"
                                    :key="permission.id"
                                    :label="permission.name"
                                    :value="permission.id"
                                ></v-radio>
                            </v-radio-group> -->
                            <v-data-table
                                :fixed-header="true"
                                :fixed-footer="true"
                                :sticky="true"
                                height="50vh"
                                :items="permissions[tabName]"
                                :headers="[
                                    {
                                        value: 'name',
                                        title: 'Name',
                                        visible: true,
                                        sortable: true,
                                    },
                                    {
                                        value: 'actions',
                                        title: 'Actions',
                                        visible: true,
                                        sortable: true,
                                    },
                                ]"
                            >
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-checkbox-btn
                                        v-model="selectedPermissions"
                                        :value="item.id"
                                    ></v-checkbox-btn>
                                </template>
                            </v-data-table>
                            <!-- <div>
                                {{ permissions[tabName] }}
                            </div> -->
                        </v-tabs-window-item>
                    </v-tabs-window>
                </v-list-item>
            </v-list>
            <v-card-actions>
                <v-btn
                    @click="handleCreateRole"
                    :disabled="
                        selectedPermissions.length === 0 ||
                        !name ||
                        !name.trim()
                    "
                    color="primary"
                >
                    {{ roleId ? "Update" : "Create" }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import {
    getPermissions,
    createRoleRequest,
    updateRoleRequest,
} from "./service";
import { ref, onMounted } from "vue";
export default {
    props: ["roleData", "roleId"],
    setup(props, { emit }) {
        const dialog = ref(true);
        const name = ref("");
        const selectedPermissions = ref([]);
        const permissions = ref([]);
        const tab = ref("tab-0");
        onMounted(async () => {
            permissions.value = (await getPermissions()) || [];
            if (props.roleData) {
                selectedPermissions.value = props.roleData.permissions;
            }
            if (props.roleId) {
                name.value = props.roleData.name;
            }
        });
        const handleCreateRole = async () => {
            if (props.roleId) {
                try {
                    await updateRoleRequest({
                        id: props.roleId,
                        name: name.value,
                        permissions: selectedPermissions.value,
                    });
                    Swal.fire({
                        title: "Success",
                        text: "Role updated successfully",
                        icon: "success",
                    });
                    emit("close");
                } catch (error) {
                    Swal.fire({
                        title: "Error",
                        text: error.message,
                        icon: "error",
                    });
                }
            } else {
                try {
                    await createRoleRequest({
                        name: name.value,
                        permissions: selectedPermissions.value,
                        type: "CUSTOM",
                    });
                    Swal.fire({
                        title: "Success",
                        text: "Role created successfully",
                        icon: "success",
                    });
                    emit("close");
                } catch (error) {
                    Swal.fire({
                        title: "Error",
                        text: error.message,
                        icon: "error",
                    });
                }
            }
        };
        return {
            handleCreateRole,
            dialog,
            name,
            selectedPermissions,
            permissions,
            tab,
        };
    },
};
</script>

<style lang="scss" scoped></style>
