<template>
    <v-row class="justify-end p-2">
        <StandardBtn
            type="custom"
            custom-icon="mdi-filter"
            custom-color="primary"
            @click="showFilter = !showFilter"
            text
        >
            {{ showFilter ? "Hide filter" : "Show filter" }}
        </StandardBtn>
    </v-row>
    <v-row>
        <v-col :md="showFilter ? 3 : 0" v-show="showFilter">
            <FilterComponent @applyFilters="handleApplyFilters" />
        </v-col>
        <v-col :md="showFilter ? 9 : 12">
            <DataTableComponent :filterData="currentFilterData" />
        </v-col>
    </v-row>
</template>

<script>
import FilterComponent from "../components/distributions/FilterComponent.vue";
import DataTableComponent from "../components/distributions/DataTableComponent.vue";

export default {
    name: "DistributionsView",
    components: {
        FilterComponent,
        DataTableComponent,
    },
    data() {
        return {
            currentFilterData: {},
            showFilter: true,
        };
    },
    methods: {
        handleApplyFilters(filterData) {
            console.log(filterData);
            this.currentFilterData = filterData;
        },
    },
};
</script>

<style scoped></style>
