<template>
    <v-row>
        <v-col md="12">
            <DataTableComponent />
        </v-col>
    </v-row>
</template>

<script>
import DataTableComponent from "../components/caps/DataTableComponent.vue";

export default {
    name: "CapsView",
    components: {
        DataTableComponent,
    },
};
</script>

<style scoped></style>
