<template>
    <div>
        <v-dialog v-model="dialog" max-width="500px" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline"
                        >{{ form.id ? "Edit" : "Create" }} API Offer Slug
                        Override</span
                    >
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <div class="col-md-12">
                                <!-- {{ form }} -->
                                <v-combobox
                                    label="Publisher Name"
                                    :disabled="true"
                                    variant="outlined"
                                    v-model="affiliate_name"
                                    item-value="id"
                                    :return-object="false"
                                    item-title="name"
                                >
                                </v-combobox>
                            </div>
                        </v-row>
                        <v-form ref="apiOfferOverRiderRef">
                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <!-- {{ form.countries}} -->
                                    <CountryCom
                                        label="Countries"
                                        variant="outlined"
                                        v-model="form.countries"
                                        multiple
                                        item-value="id"
                                        :return-object="false"
                                        clearable
                                        item-title="name"
                                        :rules="[
                                            (v) =>
                                                v?.length > 0 ||
                                                'Country is required',
                                        ]"
                                    ></CountryCom>
                                    <!-- <v-combobox
                                        label="Countries"
                                        variant="outlined"
                                        v-model="form.countries"
                                        :items="countries"
                                        multiple
                                        item-value="id"
                                        :return-object="false"
                                        clearable
                                        item-title="name"
                                        :rules="[
                                            (v) => !!v || 'Country is required',
                                        ]"
                                    >
                                        <template
                                            v-slot:selection="{ item, index }"
                                        >
                                            <v-chip
                                                small
                                                @click="
                                                    form.countries.splice(
                                                        index,
                                                        1,
                                                    )
                                                "
                                                close
                                                class="p-2"
                                            >
                                                <img
                                                    :src="item.raw.country_icon"
                                                    :alt="item.raw.code"
                                                    style="
                                                        height: 15px;
                                                        width: 15px;
                                                    "
                                                />
                                                &nbsp;
                                                {{ item.raw.name }}
                                            </v-chip>
                                        </template>
                                    </v-combobox> -->
                                </div>
                                <div class="col-md-12">
                                    <v-combobox
                                        label="Advertiser Name"
                                        variant="outlined"
                                        v-model="form.advertiser_id"
                                        :items="advertisers"
                                        item-value="id"
                                        :return-object="false"
                                        item-title="name"
                                    >
                                    </v-combobox>
                                    <v-text-field
                                        v-model="form.funnel"
                                        label="Slug"
                                        variant="outlined"
                                        type="text"
                                    ></v-text-field>
                                </div>
                                <div class="col-md-12">
                                    <v-combobox
                                        variant="outlined"
                                        v-model="form.override_values"
                                        label="Override Values"
                                        multiple
                                        :clearable="true"
                                        :rules="[
                                            (v) =>
                                                v?.length > 0 ||
                                                'Override Values is required',
                                        ]"
                                    >
                                        <template
                                            v-slot:selection="{ item, index }"
                                        >
                                            <v-chip
                                                small
                                                @click="
                                                    form.override_values.splice(
                                                        index,
                                                        1,
                                                    )
                                                "
                                                close
                                                class="p-2"
                                            >
                                                {{ item.props.title }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </div>
                            </div>
                        </v-form>
                        <!-- </v-row> -->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn
                        @click="submitForm"
                        :loading="loading"
                        color="primary"
                    >
                        submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- {{ apiOfferOverRiderData }} -->
        <div class="statusDiv mb-4">
            <!-- <v-btn @click="addRecord">Create</v-btn> -->
            <StandardBtn
                v-if="hasPermission('PUBLISHER_OVERRIDE_API_OFFERS_CREATE')"
                type="create"
                @click="addRecord"
            />
        </div>
        <Vue3Datatable
            @pageChange="handlePageChange"
            @pageSizeChange="handlePageSizeChange"
            :paginationInfo="
                'Showing {0} to {1} of {2} entries' +
                ` | Page ${page} of ${Math.ceil(apiOfferOverRiderData.length / pageSize)}`
            "
            :stickyHeader="true"
            :key="apiOfferOverRiderData?.length"
            :columns="cols"
            :rows="apiOfferOverRiderData"
            :sortable="true"
            :loading="loading"
            skin="bh-table-bordered bh-table-responsive"
        >
            <template #countries="{ value }">
                <div>
                    <span
                        class="mr-1"
                        v-for="(item, index) in value?.countries"
                        :key="item"
                    >
                        <CountryInfo :id="item" />
                        <span v-if="index < value.countries?.length - 1"
                            >,</span
                        >
                    </span>
                </div>
            </template>
            <!-- affiliate_id -->
            <template #advertiser_id="{ value }">
                <!-- {{ value }} -->
                <span>{{ getAdvertisers(value.advertiser_id) }}</span>
            </template>
            <!-- override_values -->
            <template #override_values="{ value }">
                <div>
                    <span
                        class="mr-1"
                        v-for="(item, index) in value?.override_values"
                        :key="item"
                    >
                        {{ item
                        }}<span v-if="index < value.override_values?.length - 1"
                            >,</span
                        >
                    </span>
                </div>
            </template>
            <template #actions="{ value }">
                <div class="d-flex gap-2">
                    <StandardBtn
                        v-if="
                            hasPermission('PUBLISHER_OVERRIDE_API_OFFERS_EDIT')
                        "
                        type="edit"
                        @click="editRecord(value)"
                    />
                    <StandardBtn
                        v-if="
                            hasPermission(
                                'PUBLISHER_OVERRIDE_API_OFFERS_DELETE',
                            )
                        "
                        type="delete"
                        @click="deleteLeads(value.id)"
                    />
                    <!-- <button
                        type="button"
                        class="btn btn-outline-success"
                        @click="editRecord(value)"
                    >
                        <i class="bi bi-check-circle"></i>
                        Edit
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-danger"
                        @click="deleteLeads(value.id)"
                    >
                        <i class="bi bi-x-circle"></i>
                        Delete
                    </button> -->
                </div>
            </template>
        </Vue3Datatable>
    </div>
</template>
<script setup>
import Vue3Datatable from "@bhplugin/vue3-datatable";
import Swal from "sweetalert2";
import { ref, defineProps, watch, nextTick, onMounted } from "vue";
import {
    createFunnelOverride,
    updateFunnelOverride,
    deleteFunnelOverride,
    fetchFilterData2,
} from "@/components/publishers/service";
import { useRoute } from "vue-router";
const Route = useRoute();
// import { useStore } from "vuex";
import CountryInfo from "@/components/global/CountryInfo.vue";
import CountryCom from "@/components/global/autoComplete/countryCom.vue";
import { usePermissions } from "@/composables/usePermissions";
const { hasPermission } = usePermissions();
const cols = ref([
    { field: "id", title: "ID", width: "90px", filter: false },
    { field: "countries", title: "Country" },
    { field: "advertiser_id", title: "advertiser" },
    { field: "override_values", title: "override values" },
    { field: "funnel", title: "funnel" },
    { field: "actions", title: "Actions", width: "150px", filter: false },
]);
const advertisers = ref([]);
const getAdvertisers = (id) => {
    const Advertiser = advertisers.value.find((p) => p.id == id);
    return Advertiser ? Advertiser.name : id;
};
const pageSize = ref(10);
const page = ref(1);
function handlePageChange(value) {
    page.value = value;
}
function handlePageSizeChange(value) {
    pageSize.value = value;
}
onMounted(async () => {
    const filterData = await fetchFilterData2();
    advertisers.value = await filterData["Advertisers"]["data"];
});
const Props = defineProps({
    apiOfferOverRider: {
        type: [Object, Array],
        required: true,
        default: () => [],
    },
    affiliateName: {
        type: String,
        required: true,
        default: "",
    },
});
const apiOfferOverRiderData = ref([]);
watch(
    () => Props.apiOfferOverRider,
    (newVal) => {
        apiOfferOverRiderData.value = newVal;
    },
    { immediate: true },
);
const affiliate_name = ref("");
watch(
    () => Props.affiliateName,
    (newVal) => {
        affiliate_name.value = newVal;
    },
    { immediate: true },
);
// const store = useStore();
const apiOfferOverRiderRef = ref(null);
// const overrideSearch = ref("");
const form = ref({
    id: null,
    countries: [],
    advertiser_id: null,
    funnel: "",
    override_values: [],
});
const dialog = ref(false);
// const countries = computed(() => store.getters.getCountries);
// const getCountryName = (id) => {
//     const country = store.getters.getCountries.find((c) => c.id == id);
//     return country ? country.name : id;
// };
// Method to handle form submission
const loading = ref(false);
async function submitForm() {
    const { valid } = await apiOfferOverRiderRef.value.validate();
    if (!valid) {
        return;
    }
    loading.value = true;
    form.value.affiliate_id = Route.params.id;
    // eslint-disable-next-line no-unreachable
    if (form.value.id) {
        const resp = await updateFunnelOverride(form.value.id, form.value);
        if (resp && resp.id) {
            const index = apiOfferOverRiderData.value.findIndex(
                (row) => row.id == form.value.id,
            );
            apiOfferOverRiderData.value[index] = form.value;
        }
    } else {
        delete form.value.id;
        const resp = await createFunnelOverride(form.value);
        if (resp && resp.id) {
            apiOfferOverRiderData.value.push(resp);
        }
    }
    loading.value = false;
    console.log("Form Data:", form.value);
    dialog.value = false;
}
function deleteLeads(id) {
    Swal.fire({
        title: "Are you sure?",
        text: `You are sure about to delete`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                // Call delete API
                const data = await deleteFunnelOverride(id);
                // Display success alert
                if (data) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Record deleted successfully.",
                    });
                    apiOfferOverRiderData.value =
                        apiOfferOverRiderData.value.filter(
                            (row) => row.id !== id,
                        );
                }
            } catch (error) {
                // Handle error and display an error alert
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to delete. Please try again.",
                });
                console.error("Failed to delete:", error);
            }
        }
    });
}
const editRecord = (record) => {
    console.log(
        "🚀 ~ file: offerSlugOverrideComponent.vue:376 ~ editRecord ~ record:",
        record,
    );

    form.value.id = record.id;
    form.value.countries = record.countries;
    form.value.affiliate_id = record.affiliate_id;
    form.value.funnel = record.funnel;
    form.value.override_values = record.override_values;
    console.log("Edit Record:", record);
    dialog.value = true;
};
const addRecord = async () => {
    form.value.id = null;
    dialog.value = true;
    nextTick(() => {
        apiOfferOverRiderRef.value.reset(); // reset form
        // apiOfferOverRiderRef.value.resetValidation(); // reset validation only not form
    });
};
</script>
