<template>
    <div>
        <!-- Dropdown for column visibility -->
        <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="dropdown" ref="dropdown">
                <div
                    v-show="dropdownOpen"
                    class="dropdown-content"
                    style="margin-top: 0px"
                >
                    <label v-for="col in allColumns" :key="col.field">
                        <input
                            type="checkbox"
                            :checked="col.visible"
                            @change="() => toggleColumnVisibility(col.field)"
                        />
                        {{ col.title }}
                    </label>
                </div>
            </div>

            <StandardBtn
                v-if="hasPermission('ADVERTISERS_CREATE')"
                type="create"
                @click="openCreateDialog"
            />
        </div>

        <!-- DataTable Component -->
        <div class="bh-table-responsive table-container mb-5 mt-2">
            <v-col cols="12" sm="6" md="4" lg="3" class="mb-0 pb-0">
                <v-text-field
                    label="Search"
                    v-model="searchTable"
                    hide-details
                    variant="outlined"
                    clearable
                    density="compact"
                />
            </v-col>
            <!-- <v-col cols="12" class="mb-0 pb-0">
                <div
                    @click="toggleDropdown"
                    class="text-right text-subtitle-2"
                    variant="text"
                >
                    Total Pages: {{ Math.ceil(rows.length / pageSize) }}
                </div>
            </v-col> -->
            <Vue3Datatable
                @pageChange="handlePageChange"
                @pageSizeChange="handlePageSizeChange"
                :paginationInfo="
                    'Showing {0} to {1} of {2} entries' +
                    ` | Page ${page} of ${Math.ceil(rows.length / pageSize)}`
                "
                height="65vh"
                :stickyHeader="true"
                :search="searchTable"
                :columns="visibleColumns"
                :rows="rows"
                sortColumn="created_at"
                sortDirection="desc"
                :sortable="true"
                :loading="loading"
                skin="bh-table-bordered bh-table-responsive"
            >
                <!-- Advertiser Name -->
                <template #advertiser_name="{ value }">
                    <a
                        :href="`${apiUrl}/advertisers/${value.advertiser_id}/setting`"
                        class="text-decoration-underline"
                    >
                        {{ value.advertiser_name }}
                        ({{ value.advertiser_id }})
                    </a>
                </template>

                <!-- Country -->
                <template #country_name="{ value }">
                    <div class="flex items-center gap-2">
                        <!-- Dynamically get the image source based on the country code -->
                        <img
                            v-if="getCountryCode(value.country_name)"
                            :src="`/assets/img/flags/${getCountryCode(value.country_name)}.png`"
                            width="24"
                            class="max-w-none img-flags"
                            alt="country flag"
                            onError="this.src='/img/flags/default.png'"
                        />
                        <div class="text-gray-600">
                            {{ value.country_name }}
                        </div>
                    </div>
                </template>
                <!-- Affiliate Name -->
                <template #affiliate_name="{ value }">
                    <a
                        :href="`${apiUrl}/affiliates/${value.affiliate_id}/setting`"
                        class="text-decoration-underline"
                    >
                        {{ value.affiliate_name }}
                        ({{ value.affiliate_id }})
                    </a>
                </template>

                <!-- Conversion Rate -->
                <template #cr="{ value }"> {{ value.cr }}% </template>

                <!-- Affiliate Conversion Rate -->
                <template #aff_cr="{ value }"> {{ value.aff_cr }}% </template>

                <!-- Brand Status -->
                <template #status="{ value }">
                    <span v-if="value.status === 'active'">
                        <span class="badge bg-success">{{ value.status }}</span>
                    </span>
                    <span v-if="value.status === 'inactive'">
                        <span class="badge bg-danger">{{ value.status }}</span>
                    </span>
                </template>

                <!-- Late FTD -->
                <template #late_ftd="{ value }">
                    <span v-if="value.late_ftd === 'Yes'">
                        <span class="badge bg-success">{{
                            value.late_ftd
                        }}</span>
                    </span>
                    <span v-else>
                        <span class="badge bg-danger">{{
                            value.late_ftd
                        }}</span>
                    </span>
                </template>

                <!-- Actions -->
                <template #actions="{ value }">
                    <div class="d-flex gap-2">
                        <StandardBtn
                            v-if="hasPermission('ADVERTISERS_EDIT')"
                            type="edit"
                            @click="
                                () =>
                                    $router.push({
                                        name: 'AdvertiserEditView',
                                        params: { id: value.id },
                                    })
                            "
                        />
                        <StandardBtn
                            v-if="hasPermission('ADVERTISERS_DELETE')"
                            type="delete"
                            @click="deleteLeads(value.id)"
                        />
                    </div>
                </template>
            </Vue3Datatable>
        </div>

        <!-- Create Dialog -->
        <v-dialog
            v-model="isCreateAdvertiserDialog"
            :disabled="create_loading"
            :loading="create_loading"
            persistent
            max-width="500"
        >
            <v-card>
                <v-card-title>Create Advertiser</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                label="Name"
                                v-model="name"
                                hide-details
                                variant="outlined"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                label="Display Name"
                                v-model="display_name"
                                hide-details
                                variant="outlined"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                label="User"
                                v-model="user"
                                :items="users"
                                placeholder="Select a user"
                                item-title="name"
                                return-object
                                variant="outlined"
                                hide-details
                                clearable
                                :rules="[
                                    (v) => v?.length > 0 || 'User is mandatory',
                                ]"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-switch
                                label="Status"
                                v-model="status"
                                :true-value="'active'"
                                :false-value="'inactive'"
                                hide-details
                                color="primary"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="isCreateAdvertiserDialog = false">
                        Cancel
                    </v-btn>

                    <v-btn color="primary" @click="createAdvertiser"
                        >Create</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";
import axios from "axios";
import { useStore } from "vuex";
import { createAdvertisersRequest, fetchUsersRequest } from "./service";
import { usePermissions } from "@/composables/usePermissions";
export default {
    name: "DataTableComponent",
    components: {
        Vue3Datatable,
    },
    props: {
        filterData: {
            type: Object,
            default: () => ({}),
        },
    },

    setup(props) {
        const { hasPermission } = usePermissions();
        const store = useStore();
        const apiUrl = computed(() => process.env.VUE_APP_API_URL);

        const isCreateAdvertiserDialog = ref(false);
        const name = ref("");
        const display_name = ref("");
        const user = ref(null);
        const status = ref("active");
        const users = ref([]);
        const create_loading = ref(false);

        const pageSize = ref(10);
        const page = ref(1);
        function handlePageChange(value) {
            page.value = value;
        }
        function handlePageSizeChange(value) {
            pageSize.value = value;
        }

        function resetCreateDialog() {
            name.value = "";
            display_name.value = "";
            user.value = null;
            status.value = "active";
        }

        function openCreateDialog() {
            create_loading.value = false;
            resetCreateDialog();
            isCreateAdvertiserDialog.value = true;
        }

        async function createAdvertiser() {
            create_loading.value = true;
            const payload = {
                name: name.value,
                display_name: display_name.value,
                user_id: user.value?.id || null,
                status: status.value,
            };

            const response = await createAdvertisersRequest(payload);
            if (!response) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to create advertiser. Please try again.",
                });
            } else {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Advertiser created successfully.",
                });
            }
            isCreateAdvertiserDialog.value = false;
            // reload page
            window.location.reload();
            create_loading.value = false;
        }

        const searchTable = ref("");
        const allColumns = ref([
            {
                field: "id",
                title: "ID",
                visible: true,
                sortable: true,
            },
            {
                field: "name",
                title: "Name",
                visible: true,
                sortable: true,
            },
            {
                field: "display_name",
                title: "Display Name",
                visible: true,
                sortable: true,
            },
            {
                field: "user_id",
                title: "Manager",
                visible: false,
                sortable: true,
            },
            {
                field: "status",
                title: "Status",
                visible: true,
                sortable: true,
            },
            {
                field: "is_test_advertiser",
                title: "Test Advertiser",
                visible: true,
                sortable: true,
            },

            {
                field: "actions",
                title: "Actions",
                visible: true,
            },
        ]);

        const rows = ref([]);
        const dropdownOpen = ref(false);
        const dropdown = ref(null);
        const countries = computed(() => store.getters.getCountries);

        const getCountryCode = (countryName) => {
            const country = countries.value.find((c) => c.name === countryName);
            return country ? country.code.toLowerCase() : "default"; // Ensure code is lowercase
        };
        function toggleDropdown() {
            dropdownOpen.value = !dropdownOpen.value;
        }

        function acceptLead(id) {
            console.log("Accept Lead: " + id);
        }

        function deleteLeads(id) {
            console.log("Reject Lead: " + id);
            Swal.fire({
                title: "Are you sure?",
                text: `You are sure about to delete`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const url = `/api/advertisers/${id}`;

                    try {
                        // Make the POST request with axios
                        const response = await axios.delete(url, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        });
                        // Check if the response has a message
                        if (response && response.data) {
                            // Display success alert if the response contains the message
                            Swal.fire({
                                icon: "success",
                                title: "Successfully deleted",
                                text: response.data.message,
                            });
                            rows.value = rows.value.filter(
                                (row) => row.id !== id,
                            );
                        } else {
                            throw new Error("Unexpected response format");
                        }
                    } catch (error) {
                        // Handle error and display an error alert
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Failed to delete. Please try again.",
                        });

                        console.error("Failed to delete:", error);
                    }
                }
            });
        }

        function toggleColumnVisibility(field) {
            const column = allColumns.value.find((col) => col.field === field);
            if (column) {
                column.visible = !column.visible;
            }
        }

        const visibleColumns = computed(() => {
            return allColumns.value.filter((col) => col.visible);
        });

        const filteredRows = computed(() => {
            return rows.value.filter((row) => {
                return Object.keys(props.filterData.filters || {}).every(
                    (key) => {
                        return (
                            !props.filterData.filters[key] ||
                            row[key] === props.filterData.filters[key]
                        );
                    },
                );
            });
        });

        async function fetchDataWithFilter() {
            const url = `/api/advertisers`;

            console.log("Fetching data from URL:", url);

            try {
                const { data } = await axios.get(url);
                rows.value = data; // Assuming rows is a reactive variable in Vue 3

                console.log("Received data:", data);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        }

        function handleClickOutside(event) {
            if (dropdown.value && !dropdown.value.contains(event.target)) {
                dropdownOpen.value = false;
            }
        }

        onMounted(async () => {
            fetchDataWithFilter();

            document.addEventListener("click", handleClickOutside);

            users.value = await fetchUsersRequest();
            console.log(
                "🚀 ~ file: DataTableComponent.vue:425 ~ onBeforeUnmount ~ users:",
                users,
            );
        });

        onBeforeUnmount(async () => {
            document.removeEventListener("click", handleClickOutside);
        });

        return {
            hasPermission,
            rows,
            allColumns,
            searchTable,
            page,
            pageSize,
            handlePageSizeChange,
            handlePageChange,
            visibleColumns,
            filteredRows,
            toggleColumnVisibility,
            dropdownOpen,
            dropdown,
            toggleDropdown,
            apiUrl,
            acceptLead,
            deleteLeads,
            getCountryCode,

            // Dialog
            isCreateAdvertiserDialog,
            resetCreateDialog,
            openCreateDialog,
            createAdvertiser,
            name,
            display_name,
            user,
            users,
            status,
            create_loading,
        };
    },
};
</script>

<style scoped>
.table-container {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
    padding: 10px;
    max-height: 400px;
    overflow: scroll;
}

.dropdown-content label {
    display: block;
    padding: 5px 0;
}

.dropdown-content label:hover {
    background-color: #f1f1f1;
}
.img-flags {
    width: 15px;
    height: 15px;
}
</style>
