<template>
    <div style="width: 300px">
        <div v-for="(day, index) in days" :key="`day-${index}`">
            <div v-if="day.id" class="d-flex gap-2">
                <div style="width: 85px">{{ day.label }}</div>
                <div>
                    <div v-if="!day.checked">Closed</div>
                    <div v-else>
                        <div v-for="(step, j) in day.steps" :key="`step-${j}`">
                            {{ step.start_time }}&nbsp;-&nbsp;{{
                                step.end_time
                            }}
                        </div>
                    </div>
                </div>
                <div
                    v-if="day.traffic"
                    :class="{
                        'badge bg-danger': day.traffic,
                        'badge bg-warning': !day.traffic,
                    }"
                >
                    {{ !day.traffic ? "traffic" : "no traffic" }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        days: {
            type: Array,
        },
    },
};
</script>

<style lang="scss" scoped></style>
