<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="editModalLabel">
                    Edit leadStatusGroup
                </h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="handleSave">
                    <div class="mb-3">
                        <label for="name" class="form-label">Group Name </label>
                        <input
                            type="text"
                            class="form-control"
                            id="name"
                            v-model="form.name"
                            required
                        />
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">Statuses</label>
                        <input
                            type="email"
                            class="form-control"
                            id="email"
                            v-model="form.email"
                            required
                        />
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-dark"
                    data-bs-dismiss="modal"
                >
                    Close
                </button>
                <button
                    type="button"
                    class="btn btn-secondary"
                    @click="handleSave"
                >
                    Save changes
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, defineProps } from "vue";

// Define props
const props = defineProps({
    editId: {
        type: [Number, String],
        default: null,
    },
});

// Define reactive form object
const form = ref({
    name: "",
    email: "",
    role: "",
    password: "",
});

// Watch for changes in the editId prop to load the lead data
watch(
    () => props.editId,
    (newId) => {
        if (newId) {
            // Placeholder for actual data fetching logic
            form.value = {
                name: "John Doe", // Replace with actual data fetching
                email: "john.doe@example.com",
                role: "operator",
                password: "", // Password might not be pre-filled for security reasons
            };
        }
    },
    { immediate: true },
);

// Handle form submission
function handleSave() {
    console.log("Form Data:", form.value);
    // Add your save logic here
}
</script>

<style scoped>
/* Add your custom styles here */
</style>
