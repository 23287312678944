<template>
    <div class="d-inline-flex align-center gap-2">
        <img
            v-if="country?.code && flag"
            :src="`/assets/img/flags/${country?.code?.toLowerCase()}.png`"
            width="24"
            class="max-w-none img-flags"
            :alt="country?.name"
        />
        <div class="text-gray-600" v-if="label && country?.name">
            {{ country?.name }}
        </div>
        <div v-if="!country?.code" class="d-inline-flex gap-2 align-center">
            <v-icon>mdi-earth</v-icon>
            <span>Global</span>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
    props: {
        id: {
            type: [String, Number],
        },
        code: {
            type: String,
        },
        name: {
            type: String,
        },
        flag: {
            type: Boolean,
            default: true,
        },
        label: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const store = useStore();

        const countries = computed(() => store.getters.getCountries);
        const country = computed(() =>
            countries.value.find(
                (c) =>
                    c.id == props.id ||
                    c.code?.trim()?.toLowerCase() ==
                        props.code?.trim()?.toLowerCase() ||
                    c.name?.trim()?.toLowerCase() ==
                        props.name?.trim()?.toLowerCase(),
            ),
        );

        return {
            country,
        };
    },
};
</script>

<style lang="scss" scoped></style>
