<template>
    <div class="d-inline-flex items-center gap-2">
        <div class="text-gray-600">
            {{ timezone?.name }}
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
    props: {
        id: {
            type: String,
        },
    },
    setup(props) {
        const store = useStore();

        const timezones = computed(() => store.getters.getTimeZones);
        const timezone = computed(() =>
            timezones.value.find((c) => c.id == props.id),
        );

        return {
            timezone,
        };
    },
};
</script>

<style lang="scss" scoped></style>
