import axios from "axios";
const getAdvertiserById = async (id) => {
    console.log("Get advertiser by id:", id);
    try {
        const url = `/api/advertisers/${id}/settings`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const updateAdvertiser = async (data) => {
    console.log("Update advertiser payload", data);
    try {
        const url = `/api/advertisers`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// api/advertisers/revenue
const createAdvertiserRevenue = async (data) => {
    console.log("Create advertiser revenue payload", data);
    try {
        const url = `/api/advertisers/revenue`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const updateAdvertiserRevenue = async (data) => {
    console.log("Update advertiser revenue payload", data);
    try {
        const url = `/api/advertisers/revenue`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const deleteAdvertiserRevenue = async (data) => {
    console.log("Delete advertiser revenue payload", data);
    try {
        const url = `/api/advertisers/revenue`;
        const response = await axios.delete(url, { data });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const fetchFilterData2 = async () => {
    console.log("Fetch filter data 2");
    try {
        const url = `/api/get-filter-data-2`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const fetchTimeZones = async () => {
    console.log("Fetch time zones");
    try {
        const url = `/api/timezone`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};
// createAdvertiserExclusion,
const createAdvertiserExclusion = async (data) => {
    console.log("Create advertiser exclusion payload", data);
    try {
        const url = `/api/advertisers/exclusion`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// updateAdvertiserExclusion,
const updateAdvertiserExclusion = async (data) => {
    console.log("Update advertiser exclusion payload", data);
    try {
        const url = `/api/advertisers/exclusion`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// deleteAdvertiserExclusion,
const deleteAdvertiserExclusion = async (data) => {
    console.log("Delete advertiser exclusion payload", data);
    try {
        const url = `/api/advertisers/exclusion`;
        const response = await axios.delete(url, { data });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// create supported country
// {{host}}/api/advertisers/supported-countries
const createSupportedCountry = async (data) => {
    console.log("Create supported country payload", data);
    try {
        const url = `/api/advertisers/supported-countries`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// update supported country
// {{host}}/api/advertisers/supported-countries
const updateSupportedCountry = async (data) => {
    console.log("Update supported country payload", data);
    try {
        const url = `/api/advertisers/supported-countries`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// delete supported country
// {{host}}/api/advertisers/supported-countries
// {
//     "advertiser_id" : "18",
//     "country_id":"3"
//     }
const deleteSupportedCountry = async (data) => {
    console.log("Delete supported country payload", data);
    try {
        const url = `/api/advertisers/supported-countries`;
        const response = await axios.delete(url, { data });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {{host}}/api/funnel-override
// {
//     "countries" : [1, 2],
//     "affiliate_id" : 1,
//     "advertiser_id": 1,
//     "funnel" : "example funnel to override",
//     "override_values": []
// }
// API OFFER
const createFunnelOverride = async (data) => {
    console.log("Create funnel override payload", data);
    try {
        const url = `/api/funnel-override`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const updateFunnelOverride = async (id, data) => {
    console.log("Update funnel override payload", id, "->", data);
    try {
        const url = `/api/funnel-override/${id}`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {{host}}/api/funnel-override
const deleteFunnelOverride = async (id) => {
    console.log("Delete funnel override", id);
    try {
        const url = `/api/funnel-override/${id}`;
        const response = await axios.delete(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {{host}}/api/publisher-override
// {
//     "country_id" : 1,
//     "affiliate_id" : 1,
//     "advertiser_id": 1,
//     "override_values": []
// }
//  AFFILIATE API
const createPublisherOverride = async (data) => {
    console.log("Create publisher override payload", data);
    try {
        const url = `/api/publisher-override`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const updatePublisherOverride = async (id, data) => {
    console.log("Update publisher override payload", id, "->", data);
    try {
        const url = `/api/publisher-override/${id}`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {{host}}/api/publisher-override
const deletePublisherOverride = async (id) => {
    console.log("Delete publisher override", id);
    try {
        const url = `/api/publisher-override/${id}`;
        const response = await axios.delete(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
//{{host}}/api/advertisers/opening-hours
const createOpeningHours = async (data) => {
    console.log("Create opening hours payload", data);
    try {
        const url = `/api/advertisers/opening-hours`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const updateOpeningHours = async (data) => {
    console.log("Update opening hours payload", data);
    try {
        const url = `/api/advertisers/opening-hours`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const deleteOpeningHours = async (data) => {
    console.log("Delete opening hours payload", { data });
    try {
        const url = `/api/advertisers/opening-hours`;
        const response = await axios.delete(url, { data });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const createAdvertisersRequest = async (data) => {
    console.log("Create advertisers request payload", data);
    try {
        const url = `/api/advertisers`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const fetchUsersRequest = async () => {
    console.log("Fetch users request");
    try {
        const url = `/api/users`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};
export {
    fetchTimeZones,
    getAdvertiserById,
    updateAdvertiser,
    createAdvertiserRevenue,
    updateAdvertiserRevenue,
    deleteAdvertiserRevenue,
    fetchFilterData2,
    createAdvertiserExclusion,
    updateAdvertiserExclusion,
    deleteAdvertiserExclusion,
    createSupportedCountry,
    updateSupportedCountry,
    deleteSupportedCountry,
    createFunnelOverride,
    updateFunnelOverride,
    deleteFunnelOverride,
    createPublisherOverride,
    updatePublisherOverride,
    deletePublisherOverride,
    createOpeningHours,
    updateOpeningHours,
    deleteOpeningHours,
    createAdvertisersRequest,
    fetchUsersRequest,
};
