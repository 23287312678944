import axios from "axios";

export const getDefaultColumns = async (payload) => {
    try {
        const url = `/api/user-settings/${payload.user_id}/table-columns`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const setDefaultColumnsReq = async (payload) => {
    console.log("Payload:", payload);
    // payload = {
    //     user_id: 200, // user id
    //     extras: {
    //         reports_columns: [],
    //         pending_conversion_columns: [],
    //         lead_details_columns: [],
    //     },
    // };
    try {
        const url = `/api/user-settings`;
        const response = await axios.put(url, payload);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchCountries = async () => {
    try {
        const url = `/api/countries`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const fetchFilterData = async () => {
    try {
        const url = `/api/get-filter-data`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const sendRejectRequest = async (payload) => {
    try {
        const url = `/api/pending-conversions/action`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchLeadPivot = async (payload) => {
    try {
        const url = `/api/lead-pivot`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
