<template>
    <div>
        <v-autocomplete
            :label="label"
            v-model="selectedCountry"
            variant="outlined"
            :items="countries"
            :item-title="itemTitle"
            :item-value="itemValue"
            :multiple="multiple"
            :clearable="clearable"
            :rules="rules"
        >
            <template v-slot:selection="{ item, index }">
                <v-chip small @click="removeCountry(index)" close class="p-2">
                    <img
                        :src="item.raw.country_icon"
                        :alt="item.raw.code"
                        class="img-flags"
                    />
                    &nbsp;
                    {{ item.raw.name }}
                </v-chip>
            </template>
        </v-autocomplete>
    </div>
</template>
<script setup>
import { computed, ref, defineEmits, defineProps, watch } from "vue";
import { useStore } from "vuex";
const store = useStore();
// Initialize selectedCountry as a ref to make it reactive
const selectedCountry = ref([]);
const Emit = defineEmits(["update:modelValue"]);
// Function to remove the selected country
const removeCountry = (index) => {
    selectedCountry.value.splice(index, 1);
    selectedCountry.value = [...selectedCountry.value];
};
const Props = defineProps({
    modelValue: {
        type: Array,
        default: () => [],
    },
    label: {
        type: String,
        default: "Countries",
    },
    multiple: {
        type: Boolean,
        default: true,
    },
    clearable: {
        type: Boolean,
        default: true,
    },
    itemTitle: {
        type: String,
        default: "name",
    },
    itemValue: {
        type: String,
        default: "id",
    },
    required: {
        type: Boolean,
        default: false,
    },
    rules: {
        type: Array,
        default: () => [(v) => !!v || "Country is required"],
    },
});
watch(
    selectedCountry,
    (newValue) => {
        Emit("update:modelValue", newValue);
    },
    { deep: true },
);
// Watch for changes in the modelValue prop
watch(
    () => Props.modelValue,
    (newValue) => {
        selectedCountry.value = newValue;
    },
    { immediate: true },
);
// Get the list of countries from the Vuex store
const countries = computed(() => store.getters.getCountries);
// Define the props
</script>
<style>
.img-flags {
    width: 15px;
    height: 15px;
}
</style>
