<template>
    <UnauthorisedLayout v-if="!user?.token">
        <router-view></router-view>
    </UnauthorisedLayout>
    <DefaultLayout v-else>
        <router-view></router-view>
    </DefaultLayout>
</template>
<script>
import DefaultLayout from "./layout/DefaultLayout.vue";
import UnauthorisedLayout from "./layout/UnauthorisedLayout.vue";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
    name: "App",
    components: {
        DefaultLayout,
        UnauthorisedLayout,
    },
    setup() {
        const store = useStore();
        const userData = localStorage.getItem("user_data");
        if (userData) {
            store.commit("setUser", JSON.parse(userData));
        }
        const user = computed(() => store.getters.getUser);
        return {
            user,
        };
    },
};
</script>
<style>
.bh-datatable {
    flex-direction: column-reverse !important;
    display: flex !important;
}
.swal2-container {
    z-index: 99999 !important;
}
</style>
