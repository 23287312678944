import axios from "axios";

export const fetchFilterData = async () => {
    try {
        const url = `/api/get-filter-data`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchStatusGroups = async () => {
    try {
        const url = `/api/status-groups`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const updateStatusGroup = async (data) => {
    try {
        const url = `/api/status-groups`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const createStatusGroup = async (data) => {
    try {
        const url = `/api/status-groups`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const deleteStatusGroup = async (id) => {
    try {
        const url = `/api/status-groups/${id}`;
        const response = await axios.delete(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
