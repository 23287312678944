<template>
    <div>
        <v-card>
            <v-card-title>
                <div class="d-flex align-center">
                    <i class="fas fa-user-tie"></i>
                    <span class="ml-2">Top Advertisers</span>
                </div>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="topAdvertisers"
                    :fixed-header="true"
                    :fixed-footer="true"
                    :sticky="true"
                    height="50vh"
                    :sort-by="[{ key: 'ftd', order: 'desc' }]"
                    :loading="loading"
                    v-model:items-per-page="pageSize"
                    :hide-default-footer="true"
                    @update:itemsPerPage="handlePageSizeChange"
                    @update:page="handlePageChange"
                >
                    <template
                        v-slot:top="{ pagination, options, updateOptions }"
                    >
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div class="ml-5 text-nowrap">
                                {{ paginationInfo }}
                            </div>
                            <v-data-table-footer
                                :pagination="pagination"
                                :options="options"
                                @update:options="updateOptions"
                                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                            />
                        </div>
                    </template>
                    <template #[`item.revenue`]="{ item }">
                        <span v-if="item.revenue"
                            >$&nbsp;{{ item.revenue }}</span
                        >
                        <span v-else> $&nbsp;0</span>
                    </template>
                    <template #[`item.advertiser_name`]="{ item }">
                        <v-btn
                            variant="text"
                            color="primary"
                            :to="`/edit/advertiser/${item.advertiser_id}`"
                            class="text-decoration-underline pa-0"
                            @click.stop
                        >
                            {{ item.advertiser_name }}
                            <!-- ({{ item.advertiser_id }}) -->
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { fetchTopAdvertisers } from "./service";
export default {
    setup() {
        const loading = ref(false);
        const headers = ref([
            {
                value: "advertiser_id",
                title: "ID",
                visible: true,
                sortable: true,
            },
            {
                value: "advertiser_name",
                title: "Advertiser",
                visible: true,
                sortable: true,
            },
            {
                value: "revenue",
                title: "Revenue",
                visible: true,
                sortable: true,
            },
            {
                value: "ftd",
                title: "Conversions",
                visible: true,
                sortable: true,
            },
        ]);
        const topAdvertisers = ref([]);
        const pageSize = ref(100);
        const page = ref(1);
        function handlePageChange(value) {
            page.value = value;
        }
        function handlePageSizeChange(value) {
            pageSize.value = value;
        }
        const paginationInfo = computed(() => {
            if (Math.ceil(topAdvertisers.value.length / pageSize.value) === 0) {
                return "";
            } else if (pageSize.value === -1) {
                return `Page 1 of 1`;
            } else {
                return `Page ${page.value} of ${Math.ceil(topAdvertisers.value.length / pageSize.value)}`;
            }
        });
        onMounted(async () => {
            loading.value = true;
            const response = await fetchTopAdvertisers();
            console.log(response.data);
            topAdvertisers.value = response.data;
            loading.value = false;
        });
        return {
            topAdvertisers,
            headers,
            loading,
            paginationInfo,
            pageSize,
            page,
            handlePageChange,
            handlePageSizeChange,
        };
    },
};
</script>

<style lang="scss" scoped></style>
