<template>
    <div>
        <DataTableComponent />
    </div>
</template>

<script>
import DataTableComponent from "@/components/roles/DataTableComponent.vue";
export default {
    components: {
        DataTableComponent,
    },
    setup() {
        return {};
    },
};
</script>

<style lang="scss" scoped></style>
