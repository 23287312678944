<template>
    <div>
        <div class="dropdown d-flex gap-2" ref="dropdown">
            <button
                class="btn-shadow btn btn-dark btn-sm rounded dense mb-4"
                @click="toggleDropdown"
            >
                Columns
            </button>
            <div
                v-show="dropdownOpen"
                class="dropdown-content"
                style="margin-top: 0px; z-index: 11"
            >
                <label v-for="col in allColumns" :key="col.field">
                    <input
                        type="checkbox"
                        :checked="col.visible"
                        @change="() => toggleColumnVisibility(col)"
                    />
                    {{ col.title }}
                </label>
            </div>
        </div>
        <v-data-table
            :fixed-header="true"
            :fixed-footer="true"
            :sticky="true"
            height="60vh"
            color="#f00"
            :headers="visibleColumns"
            :items="rows"
            :loading="loading"
            :items-per-page-options="[10, 15, 30, 50, 100, 200, 500, 1000]"
            show-select
            v-model="slectedTableRows"
            class="elevation-1"
            v-model:items-per-page="pageSize"
            :hide-default-footer="true"
            @update:itemsPerPage="handlePageSizeChange"
            @update:page="handlePageChange"
        >
            <template v-slot:top="{ pagination, options, updateOptions }">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="ml-5">{{ paginationInfo }}</div>
                    <v-data-table-footer
                        :pagination="pagination"
                        :options="options"
                        @update:options="updateOptions"
                        items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
export default {
    setup() {
        const dropdownOpen = ref(false);
        const dropdown = ref(null);

        const allColumns = ref([
            {
                value: "country",
                title: "Country",
                visible: true,
                sortable: true,
            },
            {
                value: "clicks",
                title: "Clicks",
                visible: true,
                sortable: true,
            },
            {
                value: "gross_clicks",
                title: "Gross clicks",
                visible: true,
                sortable: true,
            },
            {
                value: "leads",
                title: "Leads",
                visible: true,
                sortable: true,
            },
            {
                value: "c2l",
                title: "C2L",
                visible: true,
                sortable: true,
            },
            {
                value: "ftds",
                title: "FTDs",
                visible: true,
                sortable: true,
            },
            {
                value: "c2f",
                title: "C2F",
                visible: true,
                sortable: true,
            },
            {
                value: "late_ftds",
                title: "Late FTDs",
                visible: true,
                sortable: true,
            },
            {
                value: "cr",
                title: "CR",
                visible: true,
                sortable: true,
            },
            {
                value: "payout",
                title: "Payout",
                visible: true,
                sortable: true,
            },
            {
                value: "ppc",
                title: "PPC",
                visible: true,
                sortable: true,
            },
            {
                value: "epc",
                title: "EPC",
                visible: true,
                sortable: true,
            },
            {
                value: "revenue",
                title: "Revenue",
                visible: true,
                sortable: true,
            },
            {
                value: "profit",
                title: "Profit",
                visible: true,
                sortable: true,
            },
            {
                value: "margin",
                title: "Margin",
                visible: true,
                sortable: true,
            },
            {
                value: "ecpl",
                title: "eCPL",
                visible: true,
                sortable: true,
            },
            {
                value: "aecpl",
                title: "AeCPL",
                visible: true,
                sortable: true,
            },
        ]);

        const rows = ref([]);

        const pageSize = ref(100);
        const page = ref(1);
        function handlePageChange(value) {
            page.value = value;
        }
        function handlePageSizeChange(value) {
            pageSize.value = value;
        }
        const paginationInfo = computed(() => {
            if (Math.ceil(rows.value.length / pageSize.value) === 0) {
                return "";
            } else if (pageSize.value === -1) {
                return `Page 1 of 1`;
            } else {
                return `Page ${page.value} of ${Math.ceil(rows.value.length / pageSize.value)}`;
            }
        });

        function toggleColumnVisibility(field) {
            const column = allColumns.value.find(
                (col) => col.value === field.value,
            );
            if (column) {
                column.visible = !column.visible;
            }
        }

        const visibleColumns = computed(() => {
            return allColumns.value.filter((col) => col.visible);
        });
        function toggleDropdown() {
            dropdownOpen.value = !dropdownOpen.value;
        }
        function handleClickOutside(event) {
            if (dropdown.value && !dropdown.value.contains(event.target)) {
                dropdownOpen.value = false;
            }
        }
        onMounted(async () => {
            document.addEventListener("click", handleClickOutside);
        });
        onBeforeUnmount(() => {
            document.removeEventListener("click", handleClickOutside);
        });
        return {
            allColumns,
            visibleColumns,
            toggleColumnVisibility,
            dropdownOpen,
            dropdown,
            toggleDropdown,
            pageSize,
            page,
            handlePageChange,
            handlePageSizeChange,
            paginationInfo,
        };
    },
};
</script>

<style scoped>
::v-deep thead > tr,
::v-deep th {
    background: black !important;
    color: white;
}
</style>
