<template>
    <v-row>
        <v-col md="12">
            <DataTableComponent :filterData="currentFilterData" />
        </v-col>
    </v-row>
</template>
<script>
import DataTableComponent from "../components/users/DataTableComponent.vue";
export default {
    name: "UsersView",
    components: {
        DataTableComponent,
    },
    data() {
        return {
            currentFilterData: {},
        };
    },
    methods: {
        handleApplyFilters(filterData) {
            this.currentFilterData = filterData;
        },
    },
};
</script>
<style scoped></style>
