import axios from "axios";
const getRoles = async () => {
    console.log("Get roles");
    try {
        const url = `/api/roles`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const getPermissions = async () => {
    console.log("Get permissions");
    try {
        const url = `/api/permissions`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const createUser = async (data) => {
    try {
        const url = `/api/users`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error(error.data.message);
        // return null;
    }
};
const updateUser = async (data) => {
    try {
        const url = `/api/users`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error(error.data.message);
        // return null;
    }
};
const deleteUser = async (id) => {
    try {
        const url = `/api/users/${id}`;
        const response = await axios.delete(url);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error(error.data.message);
        // return null;
    }
};
export { createUser, updateUser, deleteUser, getPermissions, getRoles };
