import axios from "axios";
const getRoles = async () => {
    console.log("Get roles");
    try {
        const url = `/api/roles`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const getPermissions = async () => {
    console.log("Get permissions");
    try {
        const url = `/api/permissions`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const createRoleRequest = async (payload) => {
    console.log("Create role", payload);
    try {
        const url = `/api/roles`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error(error.data.message);
        // return null;
    }
};

const updateRoleRequest = async ({ id, name, permissions }) => {
    console.log("Update role", id, name, permissions);
    try {
        const url = `/api/roles/${id}`;
        const response = await axios.put(url, { name, permissions });
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error(error.data.message);
        // return null;
    }
};

const deleteRoleById = async (id) => {
    console.log("Delete role by id", id);
    try {
        const url = `/api/roles/${id}`;
        const response = await axios.delete(url);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error(error.data.message);
        // return null;
    }
};
export {
    getRoles,
    getPermissions,
    createRoleRequest,
    deleteRoleById,
    updateRoleRequest,
};
