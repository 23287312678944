<template>
    <v-card>
        <v-card-title>
            <div class="d-flex justify-content-between">
                <div class="d-flex align-center">
                    <v-icon>mdi-earth</v-icon>
                    <span class="ml-2">Global Statistics</span>
                </div>
                <div>
                    <v-select
                        v-model="selectedPeriod"
                        :items="periods"
                        item-title="text"
                        item-value="value"
                        variant="plain"
                    ></v-select>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <div class="d-flex justify-content-between mb-4">
                <div class="d-flex flex-column align-center">
                    <div
                        class="rounded-circle bg-primary p-2 d-flex align-center justify-center"
                        style="width: 3rem; height: 3rem"
                    >
                        <i
                            class="fas fa-piggy-bank"
                            style="font-size: 1.5rem"
                        ></i>
                    </div>
                    <div class="text-subtitle-1">Total Conversions</div>

                    <div v-if="!loading" class="text-h6">
                        {{ stats.current_period.ftd ?? 0 }}
                    </div>
                    <v-skeleton-loader v-else type="avatar"></v-skeleton-loader>
                    <div class="text-subtitle-1">
                        {{
                            selectedPeriod === "today"
                                ? "Yesterday"
                                : selectedPeriod === "this_week"
                                  ? "Last week"
                                  : "Last month"
                        }}
                    </div>
                    <div v-if="!loading" class="text-h6">
                        {{ stats.previous_period.ftd ?? 0 }}
                    </div>
                    <v-skeleton-loader v-else type="avatar"></v-skeleton-loader>
                    <div class="text-h6" v-if="!loading">
                        <span
                            v-if="stats.comparison.ftd == 0"
                            class="text-subtitle-1 text-success d-flex align-center"
                        >
                            <!-- <v-icon size="small">mdi-arrow-up</v-icon> -->
                            &nbsp;{{ stats.comparison.ftd }}%
                        </span>
                        <span
                            v-else-if="stats.comparison.ftd > 0"
                            class="text-subtitle-1 text-success d-flex align-center"
                        >
                            <v-icon size="small">mdi-arrow-up</v-icon>
                            &nbsp;+{{ stats.comparison.ftd }}%
                        </span>
                        <span
                            v-else
                            class="text-subtitle-1 text-error d-flex align-center"
                        >
                            <v-icon size="small">mdi-arrow-down</v-icon>
                            &nbsp;-{{ stats.comparison.ftd }}%
                        </span>
                    </div>
                    <v-skeleton-loader v-else type="avatar"></v-skeleton-loader>
                </div>
                <div class="d-flex flex-column align-center">
                    <div
                        class="rounded-circle bg-primary p-2 d-flex align-center justify-center"
                        style="width: 3rem; height: 3rem"
                    >
                        <i
                            class="fas fa-dollar-sign"
                            style="font-size: 1.5rem"
                        ></i>
                    </div>
                    <div class="text-subtitle-1">Total Revenue</div>
                    <div v-if="!loading" class="text-h6">
                        $&nbsp;{{ stats.current_period.revenue ?? 0 }}
                    </div>
                    <v-skeleton-loader v-else type="avatar"></v-skeleton-loader>
                    <div class="text-subtitle-1">
                        {{
                            selectedPeriod === "today"
                                ? "Yesterday"
                                : selectedPeriod === "this_week"
                                  ? "Last week"
                                  : "Last month"
                        }}
                    </div>
                    <div v-if="!loading" class="text-h6">
                        $&nbsp;{{ stats.previous_period.revenue ?? 0 }}
                    </div>
                    <v-skeleton-loader v-else type="avatar"></v-skeleton-loader>
                    <div v-if="!loading" class="text-h6">
                        <span
                            v-if="stats.comparison.revenue == 0"
                            class="text-subtitle-1 text-success d-flex align-center"
                        >
                            <!-- <v-icon size="small">mdi-arrow-up</v-icon> -->
                            &nbsp;{{ stats.comparison.revenue }}%
                        </span>
                        <span
                            v-else-if="stats.comparison.revenue > 0"
                            class="text-subtitle-1 text-success d-flex align-center"
                        >
                            <v-icon size="small">mdi-arrow-up</v-icon>
                            &nbsp;+{{ stats.comparison.revenue }}%
                        </span>
                        <span
                            v-else
                            class="text-subtitle-1 text-error d-flex align-center"
                        >
                            <v-icon size="small">mdi-arrow-down</v-icon>
                            &nbsp;-{{ stats.comparison.revenue }}%
                        </span>
                    </div>
                    <v-skeleton-loader v-else type="avatar"></v-skeleton-loader>
                </div>
                <div class="d-flex flex-column align-center">
                    <div
                        class="rounded-circle bg-primary p-2 d-flex align-center justify-center"
                        style="width: 3rem; height: 3rem"
                    >
                        <i
                            class="fas fa-money-bill"
                            style="font-size: 1.5rem"
                        ></i>
                    </div>
                    <div class="text-subtitle-1">Total Payout</div>
                    <div v-if="!loading" class="text-h6">
                        $&nbsp;{{ stats.current_period.payout ?? 0 }}
                    </div>
                    <v-skeleton-loader v-else type="avatar"></v-skeleton-loader>
                    <div class="text-subtitle-1">
                        {{
                            selectedPeriod === "today"
                                ? "Yesterday"
                                : selectedPeriod === "this_week"
                                  ? "Last week"
                                  : "Last month"
                        }}
                    </div>
                    <div v-if="!loading" class="text-h6">
                        $&nbsp;{{ stats.previous_period.payout ?? 0 }}
                    </div>
                    <v-skeleton-loader v-else type="avatar"></v-skeleton-loader>
                    <div v-if="!loading" class="text-h6">
                        <span
                            v-if="stats.comparison.payout == 0"
                            class="text-subtitle-1 text-success d-flex align-center"
                        >
                            <!-- <v-icon size="small">mdi-arrow-up</v-icon> -->
                            &nbsp;{{ stats.comparison.payout }}%
                        </span>
                        <span
                            v-else-if="stats.comparison.payout > 0"
                            class="text-subtitle-1 text-success d-flex align-center"
                        >
                            <v-icon size="small">mdi-arrow-up</v-icon>
                            &nbsp;+{{ stats.comparison.payout }}%
                        </span>
                        <span
                            v-else
                            class="text-subtitle-1 text-error d-flex align-center"
                        >
                            <v-icon size="small">mdi-arrow-down</v-icon>
                            &nbsp;-{{ stats.comparison.payout }}%
                        </span>
                    </div>
                    <v-skeleton-loader v-else type="avatar"></v-skeleton-loader>
                </div>
            </div>
            <div id="chart" v-if="series.length > 0 && !loading">
                <apexchart
                    type="area"
                    height="500"
                    :options="chartOptions"
                    :series="series"
                ></apexchart>
            </div>
            <div v-else>
                <v-skeleton-loader
                    :elevation="2"
                    type="card"
                ></v-skeleton-loader>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { fetchGlobalStatistics } from "./service";
export default {
    setup() {
        const loading = ref(false);
        const dashboardData = ref({});

        const periods = ref([
            { text: "Today", value: "today" },
            { text: "This week", value: "this_week" },
            { text: "This month", value: "this_month" },
        ]);
        const selectedPeriod = ref("today");

        const stats = computed(() => {
            if (dashboardData.value?.stats) {
                return dashboardData.value?.stats[selectedPeriod.value];
            }
            return {
                current_period: {
                    ftd: null,
                    revenue: null,
                    payout: null,
                },
                previous_period: {
                    ftd: null,
                    revenue: null,
                    payout: null,
                },
                comparison: {
                    ftd: null,
                    revenue: null,
                    payout: null,
                },
            };
        });
        const series = computed(() => {
            if (dashboardData.value?.data) {
                return JSON.parse(
                    JSON.stringify(
                        dashboardData.value?.data[selectedPeriod.value]?.series,
                    ),
                );
            }
            return [];
        });

        const chartOptions = computed(() => {
            let xaxis = [];
            if (dashboardData.value?.data) {
                xaxis = JSON.parse(
                    JSON.stringify(
                        dashboardData.value?.data[selectedPeriod.value]?.xaxis,
                    ),
                );
            }

            return {
                chart: {
                    height: 350,
                    type: "area",
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "smooth",
                },
                xaxis: xaxis,
                tooltip: {
                    x: {
                        format: "dd/MM/yy HH:mm",
                    },
                },
            };
        });

        onMounted(async () => {
            loading.value = true;
            dashboardData.value = await fetchGlobalStatistics();
            loading.value = false;
        });
        return {
            series,
            chartOptions,
            dashboardData,
            periods,
            selectedPeriod,
            stats,
            loading,
        };
    },
};
</script>

<style lang="scss" scoped></style>
