<template>
    <div style="width: 100%">
        <VueDatePicker
            v-model="date"
            :clearable="false"
            label="Date Range"
            :multi-calendars="!$vuetify.display.mdAndDown ? true : false"
            placeholder="Select Start and End date"
            :enable-time-picker="true"
            range
            :preset-dates="presetDates"
            position="right"
            time-picker-inline
            @internal-model-change="handleInternal"
        />
        <div class="v-input__details">
            <div v-if="showError" style="color: red">
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>
<script setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, onMounted, defineModel } from "vue";
import {
    startOfToday,
    endOfToday,
    startOfYesterday,
    endOfYesterday,
    startOfWeek,
    subWeeks,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    subMonths,
    format,
    startOfYear,
    endOfYear,
    subYears,
} from "date-fns";
const [date, modifiers] = defineModel({
    set(I) {
        if (modifiers.date) {
            const startDate = I?.[0] || null;
            const endDate = I?.[1] || I?.[0] || null;
            if (startDate && startDate) {
                return [
                    format(startDate, "yyyy-MM-dd HH:mm"),
                    format(endDate, "yyyy-MM-dd HH:mm"),
                ];
            } else {
                return ["", ""];
            }
        } else {
            return I;
        }
    },
});
const handleInternal = (selectedDate) => {
    // Do something
    // console.log(`Current selection - ${typeof date}`);
    // console.log(`Current selection - ${Array.isArray(date)}`);
    // console.log(`Current selection - ${Array.isArray(date) && date.length}`);
    // console.log("rangeStart", rangeStart.value);
    // console.log("rangeEnd", rangeEnd.value);

    if (Array.isArray(selectedDate) && selectedDate.length == 2) {
        const startDate = format(selectedDate[0], "yyyy-MM-dd");
        const endDate = format(selectedDate[1], "yyyy-MM-dd");
        console.log(startDate, endDate);
        setTimeout(() => {
            for (const preset of presetDates.value) {
                if (
                    format(preset.value[0], "yyyy-MM-dd") === startDate &&
                    format(preset.value[1], "yyyy-MM-dd") === endDate
                ) {
                    // console.log(preset.label);
                    const presetRange = Array.from(
                        document.querySelectorAll(".dp__btn.dp--preset-range"),
                    ).find((btn) => btn.textContent.trim() === preset.label);
                    console.log("presetRange", presetRange);

                    if (presetRange) {
                        presetRange.focus();
                    } else {
                        // console.log("presetRange not found");
                    }
                }
            }
        }, 200);
    }
};
// watch(date, (newVal) => {
//     console.log(newVal);
//     const startDate = newVal?.[0].split(" ")[0] || null;
//     const endDate = newVal?.[1].split(" ")[0] || null;
//     for (const preset of presetDates.value) {
//         if (
//             format(preset.value[0], "yyyy-MM-dd") === startDate &&
//             format(preset.value[1], "yyyy-MM-dd") === endDate
//         ) {
//             console.log(preset.label);
//             console.log("startDate", startDate);
//             console.log("endDate", endDate);
//         }
//     }
// });
const showError = ref(false);
const errorMessage = ref("");
onMounted(() => {
    // date.value = [startOfToday(new Date()), new Date()];
});
const presetDates = ref([
    {
        label: "Today",
        value: [startOfToday(new Date()), endOfToday(new Date())],
        style: {
            padding: "10px",
            fontWeight: "500",
            textAlign: "left",
        },
    },
    {
        label: "Yesterday",
        value: [startOfYesterday(new Date()), endOfYesterday(new Date())],
        style: { padding: "10px", fontWeight: "500", textAlign: "left" },
    },
    {
        label: "This week",
        value: [
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            endOfToday(new Date()),
        ],
        style: { padding: "10px", fontWeight: "500", textAlign: "left" },
    },
    {
        label: "Last week",
        value: [
            startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
            endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
        ],
        style: { padding: "10px", fontWeight: "500", textAlign: "left" },
    },
    {
        label: "This Month",
        value: [startOfMonth(new Date()), endOfToday(new Date())],
        style: { padding: "10px", fontWeight: "500", textAlign: "left" },
    },
    {
        label: "Last Month",
        value: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1)),
        ],
        style: { padding: "10px", fontWeight: "500", textAlign: "left" },
    },
    {
        label: "This Year",
        value: [startOfYear(new Date()), endOfToday(new Date())],
        style: { padding: "10px", fontWeight: "500", textAlign: "left" },
    },
    {
        label: "Last Year",
        value: [
            startOfYear(subYears(new Date(), 1)),
            endOfYear(subYears(new Date(), 1)),
        ],
        style: { padding: "10px", fontWeight: "500", textAlign: "left" },
    },
]);
</script>
<style scoped>
::v-deep .dp__instance_calendar > div {
    display: flex;
    justify-content: center;
}
::v-deep .dp--preset-dates {
    min-width: 150px;
}
::v-deep .dp--preset-range:focus {
    background-color: var(--dp-primary-color);
    color: var(--dp-primary-text-color);
}
</style>
