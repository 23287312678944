<template>
    <v-btn
        v-if="!text && icon"
        icon
        :color="colorValue"
        :variant="variant"
        :size="size"
        @click="$emit('click')"
        :disabled="disabled"
    >
        <v-icon>{{ iconValue }}</v-icon>
    </v-btn>
    <v-btn
        v-if="text && !icon"
        :color="colorValue"
        :variant="variant"
        :size="size"
        @click="$emit('click')"
        :disabled="disabled"
    >
        <slot>{{ textValue }}</slot>
    </v-btn>
    <v-btn
        v-if="text && icon"
        :prepend-icon="prependIcon ? iconValue : false"
        :append-icon="appendIcon ? iconValue : false"
        :color="colorValue"
        :variant="variant"
        :size="size"
        @click="$emit('click')"
        :disabled="disabled"
    >
        <slot>{{ textValue }}</slot>
    </v-btn>
</template>

<script>
import { ref } from "vue";
export default {
    name: "StandardBtn",
    emits: ["click"], // Declare the click event
    props: {
        type: {
            type: String,
            default: "", // create, edit, delete, view, copy, up, down, custom, accept, reject
        },
        text: {
            type: Boolean,
            default: false, // true, false
        },
        icon: {
            type: Boolean,
            default: true, // true, false
        },
        "prepend-icon": {
            type: Boolean,
            default: true, // true, false
        },
        "append-icon": {
            type: Boolean,
            default: false, // true, false
        },
        size: {
            type: String,
            default: "small", //  x-small, small, default, large, x-large
        },
        variant: {
            type: String,
            default: "outlined", // 'flat', 'text', 'elevated', 'tonal', 'outlined', 'plain'
        },
        disabled: {
            type: Boolean,
            default: false, // true, false
        },
        "custom-icon": {
            type: String,
            default: "", // icon name
        },
        "custom-color": {
            type: String,
            default: "", // color name
        },
    },
    setup(props) {
        const textValue = ref("");
        const iconValue = ref("");
        const colorValue = ref("");
        const updateValues = () => {
            switch (props.type) {
                case "create":
                    textValue.value = "Create";
                    iconValue.value = "mdi-plus";
                    colorValue.value = "primary";
                    break;
                case "edit":
                    textValue.value = "Edit";
                    iconValue.value = "mdi-pencil";
                    colorValue.value = "success";
                    break;
                case "delete":
                    textValue.value = "Delete";
                    iconValue.value = "mdi-delete";
                    colorValue.value = "error";
                    break;
                case "view":
                    textValue.value = "View";
                    iconValue.value = "mdi-eye";
                    colorValue.value = "warning";
                    break;
                case "copy":
                    textValue.value = "Copy";
                    iconValue.value = "mdi-content-copy";
                    colorValue.value = "info";
                    break;
                case "up":
                    textValue.value = "Up";
                    iconValue.value = "mdi-chevron-up";
                    colorValue.value = "primary";
                    break;
                case "down":
                    textValue.value = "Down";
                    iconValue.value = "mdi-chevron-down";
                    colorValue.value = "primary";
                    break;
                case "accept":
                    textValue.value = "Accept";
                    iconValue.value = "mdi-check";
                    colorValue.value = "success";
                    break;
                case "reject":
                    textValue.value = "Reject";
                    iconValue.value = "mdi-close";
                    colorValue.value = "error";
                    break;
                case "custom":
                    textValue.value = "Custom";
                    iconValue.value = !props.customIcon
                        ? "mdi-help"
                        : props.customIcon;
                    colorValue.value = !props.customColor
                        ? "primary"
                        : props.customColor;
                    break;
                default:
                    textValue.value = "General";
                    iconValue.value = "mdi-help";
                    colorValue.value = "primary";
                    break;
            }
        };

        updateValues();

        return {
            textValue,
            iconValue,
            updateValues,
            colorValue,
        };
    },
};
</script>

<style lang="scss" scoped></style>

<!-- HOW TO USE -->

<!-- 
<UniversalBtn type="create" />
<UniversalBtn type="edit" text></UniversalBtn>
<UniversalBtn type="edit" text>OTHER TEXT</UniversalBtn>
<UniversalBtn type="delete" />
<UniversalBtn type="view" />
<UniversalBtn type="copy" />
<UniversalBtn text :icon="false" />
<UniversalBtn text />
<UniversalBtn text size="small" />
<UniversalBtn text size="large" append-icon> okk </UniversalBtn>
<UniversalBtn text size="x-large" /> 
-->
