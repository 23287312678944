<template>
    <div>
        <v-autocomplete
            :label="label"
            v-model="timezone"
            :items="timezones"
            :item-title="itemTitle"
            :item-value="itemValue"
            variant="outlined"
            :rules="rules"
        >
        </v-autocomplete>
    </div>
</template>
<script setup>
import axios from "axios";
import { defineProps, defineModel, onMounted, ref, watch } from "vue";
const defaultTimezone = 241;
const Props = defineProps({
    id: {
        type: [Boolean, Number],
        default: false,
    },
    label: {
        type: String,
        default: "Timezone",
    },
    itemTitle: {
        type: String,
        default: "name",
    },
    itemValue: {
        type: String,
        default: "id",
    },
    rules: {
        type: Array,
        default: () => [(v) => !!v || "Timezone is required"],
    },
});
const timezone = defineModel();
watch(
    () => Props.id,
    () => {
        timezone.value = defaultTimezone;
    },
    {
        immediate: true,
    },
);
const timezones = ref([]);
const fetchTimezones = async () => {
    try {
        const url = `/api/timezone`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
onMounted(async () => {
    timezones.value = await fetchTimezones();
});
</script>
<style lang="scss" scoped></style>
