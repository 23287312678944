<template>
    <div class="card shadow-none">
        <div
            class="d-flex justify-content-between align-items-center p-3 flex-wrap"
        >
            <div class="custom-label">FILTERS</div>
            <div class="d-flex gap-2">
                <v-btn
                    color="#c8c8c8"
                    size="small"
                    class="text-none"
                    @click="resetFilters"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="indigo-darken-2"
                    class="text-none"
                    @click="applyFilters"
                    size="small"
                >
                    Apply
                </v-btn>
            </div>
        </div>
        <hr class="m-0" />
        <div class="p-2">
            <div class="mt-3">
                <div class="custom-label mb-2">Publisher</div>
                <v-autocomplete
                    v-model="selectedPublishers"
                    :items="publishers"
                    placeholder="Select publishers"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item, index }">
                        <span>
                            <v-chip
                                v-if="index === 0"
                                small
                                style="margin-left: 0px"
                            >
                                <span>{{ item.title }}</span>
                            </v-chip>
                            <v-chip
                                v-if="index === 1 && selectedPublishers"
                                small
                                class="text-caption"
                                style="font-size: 11px !important"
                            >
                                +{{ selectedPublishers.length - 1 }}
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <!-- <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="affiliate_id"
                        :label="
                            filters.includes('affiliate_id')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('affiliate_id')
                                ? 'danger'
                                : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div> -->
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Countries</div>
                <v-autocomplete
                    v-model="selectedCountries"
                    :items="countries"
                    item-title="name"
                    item-value="id"
                    placeholder="Select countries"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item, index }">
                        <span>
                            <v-chip
                                v-if="index === 0"
                                small
                                style="margin-left: 0px"
                            >
                                <span>{{ item.title }}</span>
                            </v-chip>
                            <v-chip
                                v-if="index === 1 && selectedCountries"
                                small
                                class="text-caption"
                                style="font-size: 11px !important"
                            >
                                +{{ selectedCountries.length - 1 }}
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <!-- <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="country_id"
                        :label="
                            filters.includes('country_id')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('country_id')
                                ? 'danger'
                                : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div> -->
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Method</div>
                <v-autocomplete
                    v-model="selectedMethod"
                    :items="[
                        {
                            name: 'SLOTS',
                            id: 'slot',
                        },
                        {
                            name: 'OPTIMIZE',
                            id: 'optimize',
                        },
                        {
                            name: 'CHANCE',
                            id: 'chance',
                        },
                    ]"
                    placeholder="Select publishers"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item, index }">
                        <span>
                            <v-chip
                                v-if="index === 0"
                                small
                                style="margin-left: 0px"
                            >
                                <span>{{ item.title }}</span>
                            </v-chip>
                            <v-chip
                                v-if="index === 1 && selectedPublishers"
                                small
                                class="text-caption"
                                style="font-size: 11px !important"
                            >
                                +{{ selectedPublishers.length - 1 }}
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <!-- <v-radio-group v-model="selectedMethod" inline hide-details>
                    <v-radio label="SLOTS" value="slot"></v-radio>
                    <v-radio label="CHANCE" value="chance"></v-radio>
                    <v-radio label="OPTIMIZE" value="optimize"></v-radio>
                </v-radio-group> -->
            </div>
            <!-- <div class="mt-3">
                <div class="custom-label mb-2">Advertisers</div>
                <v-autocomplete
                    v-model="selectedAdvertisers"
                    :items="advertisers"
                    item-title="name"
                    item-value="id"
                    placeholder="Select advertisers"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item, index }">
                        <span>
                            <v-chip
                                v-if="index === 0"
                                small
                                style="margin-left: 0px"
                            >
                                <span>{{ item.title }}</span>
                            </v-chip>
                            <v-chip
                                v-if="index === 1 && selectedAdvertisers"
                                small
                                class="text-caption"
                                style="font-size: 11px !important"
                            >
                                +{{ selectedAdvertisers.length - 1 }}
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="advertiser_id"
                        :label="
                            filters.includes('advertiser_id')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('advertiser_id')
                                ? 'danger'
                                : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import { fetchFilterData } from "./service";
export default {
    data() {
        return {
            // list of selected value here
            selectedPublishers: [],
            selectedCountries: [],
            selectedMethod: "slot",
            // selectedAdvertisers: [],

            // list value here
            publishers: [],
            countries: [],
            // advertisers: [],

            // filters: [],
        };
    },

    async mounted() {
        await this.fetchFiltersValues();
        // if (this.$route.query.filters) {
        //     const decodedFilters = JSON.parse(
        //         decodeURIComponent(this.$route.query.filters),
        //     );
        //     console.log("decodedFilters", decodedFilters);
        //     if (Object.keys(decodedFilters).length > 0) {
        //         await this.setQueryToFilters(decodedFilters);
        //     }
        // }
        // this.applyFilters();
    },
    methods: {
        ...mapActions(["setFilterData"]),
        // async setQueryToFilters(filterData) {
        //     this.selectedPublishers = filterData?.affiliate_id ?? [];
        //     // ? [Number(filterData?.affiliate_id)]
        //     // : [];
        //     this.selectedCountries = filterData?.country_id ?? [];
        //     // ? [Number(filterData?.country_id)]
        //     // : [];
        //     // this.selectedAdvertisers = filterData?.advertiser_id ?? [];
        //     // ? [Number(filterData?.advertiser_id)]
        //     // : [];
        //     // this.filters = filterData?.excludes ?? [];
        // },
        async fetchFiltersValues() {
            const response = await fetchFilterData();
            if (!response) return;
            this.countries = response?.countries || [];
            this.publishers = response?.publishers || [];
            // this.advertisers = response?.advertisers || [];
        },
        resetFilters() {
            this.selectedPublishers = [];
            this.selectedCountries = [];
            this.selectedMethod = "slot";
            // this.selectedAdvertisers = [];
            // this.filters = [];
        },
        async applyFilters() {
            const payload = {
                country_id: this.selectedCountries?.length
                    ? this.selectedCountries
                    : null,
                publisher_id: this.selectedPublishers?.length
                    ? this.selectedPublishers
                    : null,
                type: this.selectedMethod,
                // advertiser_id: this.selectedAdvertisers?.length
                //     ? this.selectedAdvertisers
                //     : null,
                // excludes: this.filters,
            };
            await this.setFilterData(payload);
            this.$emit("applyFilters", payload);
        },
    },
};
</script>
<style></style>
<style scoped>
.custom-label {
    font-weight: 500;
    font-size: 13px;
    color: #212121;
}
.custom-switch {
    padding-left: 10px;
}
.custom-switch >>> .v-label {
    font-size: 12px !important;
    font-weight: 600;
    line-height: 14px;
}
.custom-switch >>> .v-switch__track {
    height: 20px;
    min-width: 40px;
    border: 1px solid #989898;
    background-color: rgb(219 219 219);
}
.custom-switch >>> .v-switch__thumb {
    box-shadow: none !important;
    background-color: #a5a5a5;
    height: 14px;
    width: 14px;
}
</style>
