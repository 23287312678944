import Permissions from "@/constants/permissions.js";
import { useStore } from "vuex";
import { computed } from "vue";

export function usePermissions() {
    const store = useStore();
    const userPermissions = computed(() => store.getters.getUserPermisssion);
    const hasPermission = (permission) => {
        // if (!Permissions[permission]) {
        //     console.log(
        //         "Permission Not found:",
        //         permission,
        //         "That's why assuming true by default",
        //     );
        // }
        return (
            !Permissions[permission] ||
            userPermissions.value.includes(Permissions[permission])
        );
    };

    return {
        hasPermission,
    };
}
