import axios from "axios";
const getPublishersById = async (id) => {
    console.log("Get publishers by id: ", id);
    try {
        // {{host}}/api/publishers/5/settings
        const url = `/api/publishers/${id}/settings`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const updatePublishers = async (data) => {
    console.log("Update publishers payload", data);
    try {
        const url = `/api/publishers`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {{host}}/api/affiliate/revenue
const createAffiliateRevenue = async (data) => {
    console.log("Create affiliate revenue payload", data);
    try {
        const url = `/api/affiliate/revenue`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const updateAffiliateRevenue = async (data) => {
    console.log("Update affiliate revenue payload", data);
    try {
        const url = `/api/affiliate/revenue`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const deleteAffiliateRevenue = async (data) => {
    console.log("Delete affiliate revenue payload", data);
    try {
        const url = `/api/affiliate/revenue`;
        const response = await axios.delete(url, { data });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const fetchFilterData2 = async () => {
    try {
        const url = `/api/get-filter-data-2`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const fetchTimeZones = async () => {
    try {
        const url = `/api/timezone`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};
// createAdvertiserExclusion,
const createAdvertiserExclusion = async (data) => {
    console.log("Create advertiser exclusion payload", data);
    try {
        const url = `/api/advertisers/exclusion`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// updateAdvertiserExclusion,
const updateAdvertiserExclusion = async (data) => {
    console.log("Update advertiser exclusion payload", data);
    try {
        const url = `/api/advertisers/exclusion`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// deleteAdvertiserExclusion,
const deleteAdvertiserExclusion = async (data) => {
    console.log("Delete advertiser exclusion payload", data);
    try {
        const url = `/api/advertisers/exclusion`;
        const response = await axios.delete(url, { data });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// create supported country
// {{host}}/api/publishers/supported-countries
const createSupportedCountry = async (data) => {
    console.log("Create supported country payload", data);

    try {
        const url = `/api/publishers/supported-countries`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// update supported country
// {{host}}/api/publishers/supported-countries
const updateSupportedCountry = async (data) => {
    console.log("Update supported country payload", data);
    try {
        const url = `/api/publishers/supported-countries`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// delete supported country
// {{host}}/api/publishers/supported-countries
// {
//     "advertiser_id" : "18",
//     "country_id":"3"
//     }
const deleteSupportedCountry = async (data) => {
    console.log("Delete supported country payload", data);

    try {
        const url = `/api/publishers/supported-countries`;
        const response = await axios.delete(url, { data });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {{host}}/api/funnel-override
// {
//     "countries" : [1, 2],
//     "affiliate_id" : 1,
//     "advertiser_id": 1,
//     "funnel" : "example funnel to override",
//     "override_values": []
// }
// API OFFER
const createFunnelOverride = async (data) => {
    console.log("Create funnel override payload", data);
    try {
        const url = `/api/funnel-override`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const updateFunnelOverride = async (id, data) => {
    console.log("Update funnel override payload", id, data);
    try {
        const url = `/api/funnel-override/${id}`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {{host}}/api/funnel-override
const deleteFunnelOverride = async (id) => {
    console.log("Delete funnel override payload", id);
    try {
        const url = `/api/funnel-override/${id}`;
        const response = await axios.delete(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {{host}}/api/publisher-override
// {
//     "country_id" : 1,
//     "affiliate_id" : 1,
//     "advertiser_id": 1,
//     "override_values": []
// }
//  AFFILIATE API
const createPublisherOverride = async (data) => {
    console.log("Create publisher override payload", data);
    try {
        const url = `/api/publisher-override`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const updatePublisherOverride = async (id, data) => {
    console.log("Update publisher override payload", id, data);
    try {
        const url = `/api/publisher-override/${id}`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {{host}}/api/publisher-override
const deletePublisherOverride = async (id) => {
    console.log("Delete publisher override payload", id);
    try {
        const url = `/api/publisher-override/${id}`;
        const response = await axios.delete(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

// {{host}}/api/publishers/payout
// {

//     "affiliate_id" : "29",
//     "country_ids":"3",
//     "amount" : "11"
//     }
const createPayout = async (data) => {
    console.log("Create payout payload", data);
    try {
        const url = `/api/publishers/payout`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {{host}}/api/publishers/payout
// {
//     "advertiser_id" : "0",
//     "affiliate_id" : "18",
//     "country_ids":"3",
//     "amount" : "11"
//     }
const updatePayout = async (data) => {
    console.log("Update payout payload", data);
    try {
        const url = `/api/publishers/payout`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {
//     "payout_id" : "18"
//     }
const deletePayoutRequest = async (data) => {
    console.log("Delete payout payload", data);

    try {
        const url = `/api/publishers/payout`;
        const response = await axios.delete(url, { data });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// PUBLISHER API
const createPublishersRequest = async (data) => {
    console.log("createPublishersRequest payload", data);
    try {
        const url = `/api/publishers`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export {
    fetchTimeZones,
    getPublishersById,
    updatePublishers,
    // --------------
    createAffiliateRevenue,
    updateAffiliateRevenue,
    deleteAffiliateRevenue,
    // ----
    fetchFilterData2,
    createAdvertiserExclusion,
    updateAdvertiserExclusion,
    deleteAdvertiserExclusion,
    createSupportedCountry,
    updateSupportedCountry,
    deleteSupportedCountry,
    createFunnelOverride,
    updateFunnelOverride,
    deleteFunnelOverride,
    createPublisherOverride,
    updatePublisherOverride,
    deletePublisherOverride,
    // payout api's
    createPayout,
    updatePayout,
    deletePayoutRequest,
    // publishers api's
    createPublishersRequest,
};
