import axios from "axios";

export const fetchFilterData = async () => {
    try {
        const url = `/api/get-filter-data`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchCaps = async () => {
    try {
        const url = `/api/caps`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const updateCaps = async (data) => {
    try {
        const url = `/api/caps`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const createCaps = async (data) => {
    try {
        const url = `/api/caps`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const deleteCaps = async (id) => {
    try {
        const url = `/api/caps/${id}`;
        const response = await axios.delete(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchCountries = async () => {
    try {
        const url = `/api/countries`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchTimezones = async () => {
    try {
        const url = `/api/timezone`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
