import StandardBtn from "@/components/global/btn/StandardBtn.vue";
import CountryInfo from "@/components/global/CountryInfo.vue";
import TimeZoneInfo from "@/components/global/TimeZoneInfo.vue";
import OpeningHoursInfo from "@/components/global/OpeningHoursInfo.vue";
export function registerGlobalComponents(app) {
    app.component("StandardBtn", StandardBtn);
    app.component("CountryInfo", CountryInfo);
    app.component("TimeZoneInfo", TimeZoneInfo);
    app.component("OpeningHoursInfo", OpeningHoursInfo);
}
