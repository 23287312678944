<template>
    <v-app light>
        <v-app-bar color="bar" prominent>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-app-bar>

        <v-main>
            <v-container fluid>
                <slot></slot>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { computed } from "vue";
export default {
    name: "DefaultLayout",

    data() {
        return {
            drawer: true,
        };
    },
    mounted() {
        console.log(process.env.VUE_APP_APP_URL, "App URL"); // Logs the App URL
        console.log(process.env.VUE_APP_API_URL, "API URL"); // Logs the API URL
        console.log(process.env.VUE_APP_NAME, "App Name");
    },
    setup() {
        const title = computed(() => process.env.VUE_APP_NAME);
        return {
            title,
        };
    },
};
</script>

<style scoped>
.v-main {
    max-width: 100vw;
}
</style>
