<template>
    <v-row class="justify-end p-2">
        <StandardBtn
            type="custom"
            custom-icon="mdi-filter"
            custom-color="primary"
            @click="showFilter = !showFilter"
            text
        >
            {{ showFilter ? "Hide filter" : "Show filter" }}
        </StandardBtn>
    </v-row>
    <v-row>
        <v-col :md="showFilter ? 3 : 0" v-show="showFilter">
            <FilterComponent />
        </v-col>
        <v-col :md="showFilter ? 9 : 12">
            <DataTableComponent :filterData="filterData" />
        </v-col>
    </v-row>
</template>

<script>
import FilterComponent from "../components/pendingConversions/FilterComponent.vue";
import DataTableComponent from "../components/pendingConversions/DataTableComponent.vue";
import { mapGetters } from "vuex";

export default {
    name: "PendingConversionView",
    components: {
        FilterComponent,
        DataTableComponent,
    },
    computed: {
        ...mapGetters({ filterData: "getFilterData" }),
    },
    data() {
        return {
            showFilter: true,
        };
    },
};
</script>

<style scoped></style>
