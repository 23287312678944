<template>
    <v-row>
        <v-col md="12">
            <CreateComponent />
        </v-col>
    </v-row>
</template>

<script>
import CreateComponent from "../../components/publishers/CreateComponent.vue";

export default {
    name: "CreatePublisherView",
    components: {
        CreateComponent,
    },
};
</script>

<style scoped></style>
