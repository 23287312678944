import axios from "axios";

// dashboard/top-publishers
export const fetchTopPublishers = async () => {
    try {
        const url = `/api/dashboard/top-publishers`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

// dashboard/top-advertisers
export const fetchTopAdvertisers = async () => {
    try {
        const url = `/api/dashboard/top-advertisers`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

// dashboard/global-statistics
export const fetchGlobalStatistics = async () => {
    try {
        const url = `/api/dashboard/stats`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

// dashboard/latest-ftd
export const fetchLatestFtd = async () => {
    try {
        const url = `/api/dashboard/latest-ftd`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};
