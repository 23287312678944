<template>
    <div>
        <!-- Dropdown for column visibility -->
        <div class="dropdown" ref="dropdown"></div>

        <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="dropdown" ref="dropdown">
                <div
                    v-show="dropdownOpen"
                    class="dropdown-content"
                    style="margin-top: 0px"
                >
                    <label v-for="col in allColumns" :key="col.field">
                        <input
                            type="checkbox"
                            :checked="col.visible"
                            @change="() => toggleColumnVisibility(col.field)"
                        />
                        {{ col.title }}
                    </label>
                </div>
            </div>

            <StandardBtn
                v-if="hasPermission('STATUS_GROUPS_CREATE')"
                type="create"
                @click="createRow"
            />
        </div>

        <!-- DataTable Component -->
        <div class="bh-table-responsive table-container mb-5 mt-2">
            <v-col cols="12" sm="6" md="4" lg="3" class="mb-0 pb-0">
                <v-text-field
                    label="Search"
                    v-model="searchTable"
                    hide-details
                    variant="outlined"
                    clearable
                    density="compact"
                />
            </v-col>
            <Vue3Datatable
                @pageChange="handlePageChange"
                @pageSizeChange="handlePageSizeChange"
                :paginationInfo="
                    'Showing {0} to {1} of {2} entries' +
                    ` | Page ${page} of ${Math.ceil(rows.length / params.pagesize)}`
                "
                :search="searchTable"
                height="65vh"
                :stickyHeader="true"
                :columns="visibleColumns"
                :rows="rows"
                :sortable="true"
                :loading="loading"
                :pageSize="params.pagesize"
                :pageSizeOptions="[10, 15, 30, 50, 100, 200, 500, 1000]"
                skin="bh-table-bordered bh-table-responsive"
            >
                <!-- paginationInfo="{0} to {1} of {2}" -->
                <!-- Actions -->
                <template #actions="{ value }">
                    <div class="d-flex gap-2">
                        <StandardBtn
                            v-if="hasPermission('STATUS_GROUPS_EDIT')"
                            type="edit"
                            @click="editRow(value.id)"
                        />
                        <StandardBtn
                            v-if="hasPermission('STATUS_GROUPS_DELETE')"
                            type="delete"
                            @click="deleteRow(value.id)"
                        />
                    </div>
                </template>
                <template #statuses="{ value }">
                    <div
                        class="d-flex flex-wrap gap-2 pa-2"
                        v-if="value.statuses.length > 0"
                    >
                        <div
                            class="d-flex flex-wrap gap-2"
                            v-if="!showFull['statuses'][index]"
                        >
                            <v-chip
                                v-for="status in value.statuses.slice(0, 3)"
                                :key="status"
                                class="mx-05"
                                label
                            >
                                {{ status }}
                            </v-chip>
                            <span
                                v-if="value.statuses?.length > 3"
                                class="text-decoration-underline pa-0 cursor-pointer"
                                @click.stop="showFull['statuses'][index] = true"
                            >
                                Show more
                            </span>
                        </div>
                        <div class="d-flex flex-wrap gap-2" v-else>
                            <template
                                v-for="status in value.statuses"
                                :key="status"
                            >
                                <v-chip class="mx-05" label>
                                    {{ status }}
                                </v-chip>
                            </template>
                            <span
                                class="text-decoration-underline pa-0 cursor-pointer"
                                @click.stop="
                                    showFull['statuses'][index] = false
                                "
                            >
                                Show less
                            </span>
                        </div>
                    </div>
                    <span v-else class="ma-1"> - </span>
                </template>
            </Vue3Datatable>
        </div>

        <!-- Start Modal -->
        <v-dialog v-model="dialog" max-width="500px" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline" v-if="editId === null"
                        >Create Status Group</span
                    >
                    <span class="headline" v-else>Edit Status Group</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    label="Group Name"
                                    v-model="groupName"
                                    variant="outlined"
                                    :rules="[
                                        (value) =>
                                            !!value ||
                                            'Please enter a group name',
                                    ]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-combobox
                                    v-model="selectedStatuses"
                                    :items="statuses"
                                    label="Statuses"
                                    item-title="brand_status"
                                    item-value="brand_status"
                                    multiple
                                    variant="outlined"
                                    clearable
                                    :rules="[
                                        (value) =>
                                            !!value.length ||
                                            'Please select at least one status',
                                    ]"
                                >
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <v-chip
                                            small
                                            @click="
                                                selectedStatuses.splice(
                                                    index,
                                                    1,
                                                )
                                            "
                                            close
                                        >
                                            {{ item.title }}
                                        </v-chip>
                                    </template>
                                </v-combobox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn
                        color="blue darken-1"
                        @click="saveLeadGroup"
                        :disabled="!groupName || !selectedStatuses.length"
                    >
                        {{ editId === null ? "Create" : "Save" }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- End Modal -->
        <!-- Edit Modal -->
        <!-- <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
            ref="editModal"
        >
            <EditComponent />
        </div>
        <div
            class="modal fade"
            id="createModal"
            tabindex="-1"
            aria-labelledby="createModalLabel"
            aria-hidden="true"
            ref="createModal"
        >
            <CreateComponent />
        </div> -->
    </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, reactive } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";
// import axios from "axios";
// import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import CreateComponent from "./CreateComponent.vue";
// import EditComponent from "./EditComponent.vue";
import {
    fetchFilterData,
    fetchStatusGroups,
    createStatusGroup,
    updateStatusGroup,
    deleteStatusGroup,
} from "./service";
import { usePermissions } from "@/composables/usePermissions";
export default {
    name: "DataTableComponent",
    components: {
        Vue3Datatable,
        // CreateComponent,
        // EditComponent,
    },
    // props: {
    //     filterData: {
    //         type: Object,
    //         default: () => ({}),
    //     },
    // },

    setup() {
        const { hasPermission } = usePermissions();
        const showFull = reactive({
            statuses: [],
        });
        const params = reactive({
            current_page: 1,
            pagesize: 100,
        });
        const searchTable = ref("");
        const page = ref(1);
        function handlePageChange(value) {
            page.value = value;
        }
        function handlePageSizeChange(value) {
            params.pagesize = value;
        }
        const allColumns = ref([
            {
                field: "group",
                title: "Group Name",
                visible: true,
                sortable: true,
            },
            {
                field: "statuses",
                title: "Statuses",
                visible: true,
                sortable: true,
            },
            {
                field: "actions",
                title: "Actions",
                visible: true,
            },
        ]);

        const rows = ref([]);
        const dropdownOpen = ref(false);
        const dropdown = ref(null);

        const dialog = ref(false);
        const editId = ref(null);
        const groupName = ref("");
        const selectedStatuses = ref([]);
        const statuses = ref([]);
        // function formatStatuses(statuses) {
        //     try {
        //         const statusesArray =
        //             typeof statuses === "string"
        //                 ? JSON.parse(statuses)
        //                 : statuses;
        //         return statusesArray.join(", ");
        //     } catch (e) {
        //         console.error("Error parsing statuses:", e);
        //         return statuses;
        //     }
        // }

        function toggleDropdown() {
            dropdownOpen.value = !dropdownOpen.value;
        }
        // const editModal = ref(null);
        // const createModal = ref(null);

        async function editRow(id) {
            console.log("Accept Lead: " + id);
            // enable editId
            editId.value = id;
            // autofill form
            const statusGroup = rows.value.find((row) => row.id === id);
            groupName.value = statusGroup.group;
            selectedStatuses.value = statusGroup.statuses;
            // show modal
            dialog.value = true;
            // fetch statuses & fill the autocomplete
            try {
                const data = await fetchFilterData();
                if (!data) {
                    throw new Error("Failed to fetch data");
                }
                statuses.value = data.statuses;
            } catch (error) {
                console.error("Failed to filter data:", error);
            }
        }

        async function createRow() {
            // nextTick(() => {
            //     if (createModal.value) {
            //         const modalInstance = new Modal(createModal.value);
            //         modalInstance.show();
            //     }
            // });
            // fetch statuses & fill the autocomplete
            editId.value = null;
            dialog.value = true;
            groupName.value = "";
            selectedStatuses.value = [];
            try {
                const data = await fetchFilterData();
                statuses.value = data.statuses;
            } catch (error) {
                console.error("Failed to filter data:", error);
            }
        }

        async function saveLeadGroup() {
            console.log(editId.value);
            console.log(groupName.value);
            console.log(selectedStatuses.value);
            const data = {
                group: groupName.value,
                statuses: selectedStatuses.value.map((val) => {
                    if (typeof val === "string") {
                        return val;
                    } else {
                        return val.brand_status;
                    }
                }),
            };
            try {
                if (editId.value !== null) {
                    console.log("Edit Status Group");
                    // edit status group
                    data.id = editId.value;

                    console.log("payload", data);
                    const response = await updateStatusGroup(data);

                    if (!response) {
                        throw new Error("Failed to update");
                    }
                    Swal.fire({
                        icon: "success",
                        title: "Successfully updated",
                        text: "Status group updated successfully",
                    });
                    console.log("Edit Status Group", response);
                } else {
                    console.log("Create Status Group");
                    const response = await createStatusGroup(data);
                    if (!response) {
                        throw new Error("Failed to create");
                    }
                    Swal.fire({
                        icon: "success",
                        title: "Successfully created",
                        text: "Status group created successfully",
                    });
                    console.log("Create Status Group", response);
                }
            } catch (error) {
                console.error("Failed to save status group:", error);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to save status group. Please try again.",
                });
            } finally {
                fetchDataWithFilter();
                dialog.value = false;
            }
        }

        function deleteRow(id) {
            console.log("Reject Lead: " + id);
            Swal.fire({
                title: "Are you sure?",
                text: `You are sure about to delete`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        // Make the POST request with axios
                        const data = await deleteStatusGroup(id);
                        if (!data) {
                            throw new Error("Failed to delete");
                        }
                        Swal.fire({
                            icon: "success",
                            title: "Successfully deleted",
                            text: data.message,
                        });
                    } catch (error) {
                        // Handle error and display an error alert
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Failed to delete. Please try again.",
                        });

                        console.error("Failed to delete:", error);
                    } finally {
                        fetchDataWithFilter();
                    }
                }
            });
        }

        function toggleColumnVisibility(field) {
            const column = allColumns.value.find((col) => col.field === field);
            if (column) {
                column.visible = !column.visible;
            }
        }

        const visibleColumns = computed(() => {
            return allColumns.value.filter((col) => col.visible);
        });

        // const filteredRows = computed(() => {
        //     return rows.value.filter((row) => {
        //         return Object.keys(props.filterData.filters || {}).every(
        //             (key) => {
        //                 return (
        //                     !props.filterData.filters[key] ||
        //                     row[key] === props.filterData.filters[key]
        //                 );
        //             },
        //         );
        //     });
        // });

        async function fetchDataWithFilter() {
            // const url = `/api/status-groups`;

            // console.log("Fetching data from URL:", url);

            try {
                const data = await fetchStatusGroups();

                if (!data) {
                    throw new Error("Network response was not ok");
                }

                rows.value = data.map((obj) => {
                    return {
                        ...obj,
                        statuses: JSON.parse(obj.statuses),
                    };
                }); // Assuming rows is a reactive variable in Vue 3

                console.log("Received data:", rows.value);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        }

        function handleClickOutside(event) {
            if (dropdown.value && !dropdown.value.contains(event.target)) {
                dropdownOpen.value = false;
            }
        }

        onMounted(async () => {
            await fetchDataWithFilter();
            showFull.value = {
                statuses: rows.value.map(() => false),
            };

            document.addEventListener("click", handleClickOutside);
        });

        onBeforeUnmount(() => {
            document.removeEventListener("click", handleClickOutside);
        });

        return {
            hasPermission,
            showFull,
            rows,
            params,
            page,
            handlePageChange,
            handlePageSizeChange,
            allColumns,
            searchTable,
            visibleColumns,
            // filteredRows,
            toggleColumnVisibility,
            dropdownOpen,
            dropdown,
            toggleDropdown,
            editRow,
            deleteRow,
            // editModal,
            // formatStatuses,
            // createModal,
            createStatusGroup,
            dialog,
            editId,
            groupName,
            selectedStatuses,
            statuses,
            saveLeadGroup,
            createRow,
        };
    },
};
</script>

<style scoped>
.table-container {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
    padding: 10px;
    max-height: 400px;
    overflow: scroll;
}

.dropdown-content label {
    display: block;
    padding: 5px 0;
}

.dropdown-content label:hover {
    background-color: #f1f1f1;
}
.img-flags {
    width: 15px;
    height: 15px;
}
</style>
