<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="810">
            <v-form ref="openingHourRef">
                <v-card>
                    <v-card-title> New Timeslot </v-card-title>
                    <v-card-text>
                        <countryCom
                            label="Country Name"
                            variant="outlined"
                            required
                            v-model="form.country_ids"
                            multiple
                            item-value="id"
                            :return-object="false"
                            item-title="name"
                        >
                        </countryCom>
                        <TimeZoneCom
                            id
                            v-model="form.timezone_id"
                            label="Select a timezone"
                            item-title="name"
                            item-value="id"
                        >
                        </TimeZoneCom>
                        <div class="mt-4">
                            <div class="text-subtitle-1">Opening Hours</div>
                            <div
                                v-for="(timeSlotDay, dayIndex) in timeSlots?.[
                                    timeSlotIndex
                                ]['days']"
                                :key="timeSlotDay.id"
                                class="d-flex align-center justify-space-between flex-wrap border my-2 position-relative"
                            >
                                <div class="d-flex align-center flex-wrap">
                                    <v-checkbox
                                        v-model="timeSlotDay.checked"
                                        :label="timeSlotDay.label"
                                        color="primary"
                                        width="120px"
                                        hide-details
                                    ></v-checkbox>
                                    <v-btn
                                        icon="mdi-plus-circle"
                                        color="primary"
                                        variant="text"
                                        v-if="timeSlotDay.checked"
                                        @click="addStep(timeSlotDay)"
                                    ></v-btn>
                                    <!-- copy to all -->
                                    <v-btn
                                        prepend-icon="mdi-content-copy"
                                        color="primary"
                                        variant="text"
                                        v-if="
                                            timeSlotDay.checked &&
                                            timeSlotDay.id == 1
                                        "
                                        @click="
                                            copyStepsToAll(
                                                timeSlotDay,
                                                timeSlotIndex,
                                            )
                                        "
                                    >
                                        Copy to all
                                    </v-btn>
                                    <v-btn
                                        prepend-icon="mdi-content-copy"
                                        color="primary"
                                        variant="text"
                                        v-if="
                                            timeSlotDay.checked &&
                                            timeSlotDay.id != 1
                                        "
                                        @click="
                                            copyPreviousStep(
                                                timeSlotDay,
                                                timeSlotIndex,
                                                dayIndex - 1,
                                            )
                                        "
                                    >
                                        Copy previous
                                    </v-btn>
                                </div>
                                <div
                                    v-if="!timeSlotDay.checked"
                                    class="mr-2 text-overline"
                                >
                                    closed
                                </div>
                                <div
                                    class="d-flex align-center"
                                    v-if="timeSlotDay.checked"
                                >
                                    <div
                                        class="d-flex flex-column align-start align-md-end py-2"
                                    >
                                        <div
                                            v-for="(
                                                step, l
                                            ) in timeSlotDay.steps"
                                            :key="l"
                                        >
                                            <div
                                                class="pr-2 d-flex align-center"
                                            >
                                                <v-btn
                                                    v-if="
                                                        timeSlotDay.steps
                                                            .length > 1
                                                    "
                                                    icon="mdi-delete"
                                                    color="danger"
                                                    variant="text"
                                                    @click="
                                                        removeStep(
                                                            timeSlotDay,
                                                            l,
                                                        )
                                                    "
                                                ></v-btn>
                                                <!-- <v-text-field
                                                    style="width: 75px"
                                                    v-model="step.start_time"
                                                    :active="
                                                        step.menu.start_time
                                                    "
                                                    readonly
                                                    variant="outlined"
                                                    hide-details
                                                    density="compact"
                                                >
                                                    <v-menu
                                                        v-model="
                                                            step.menu.start_time
                                                        "
                                                        :close-on-content-click="
                                                            false
                                                        "
                                                        activator="parent"
                                                        transition="scale-transition"
                                                    >
                                                        <v-time-picker
                                                            v-if="
                                                                step.menu
                                                                    .start_time
                                                            "
                                                            v-model="
                                                                step.start_time
                                                            "
                                                            full-width
                                                            format="24hr"
                                                        ></v-time-picker>
                                                    </v-menu>
                                                </v-text-field> -->
                                                <VueDatePicker
                                                    v-model="step.start_time"
                                                    time-picker
                                                    format="HH:mm"
                                                    model-type="HH:mm"
                                                    :position="
                                                        !$vuetify.display
                                                            .smAndDown
                                                            ? 'right'
                                                            : 'center'
                                                    "
                                                >
                                                    <template #trigger>
                                                        <v-text-field
                                                            v-model="
                                                                step.start_time
                                                            "
                                                            style="width: 75px"
                                                            variant="outlined"
                                                            hide-details
                                                            readonly
                                                            density="compact"
                                                        ></v-text-field>
                                                    </template>
                                                </VueDatePicker>
                                                <div class="mx-2">-</div>
                                                <VueDatePicker
                                                    v-model="step.end_time"
                                                    time-picker
                                                    format="HH:mm"
                                                    model-type="HH:mm"
                                                    :position="
                                                        !$vuetify.display
                                                            .smAndDown
                                                            ? 'right'
                                                            : 'center'
                                                    "
                                                >
                                                    <template #trigger>
                                                        <v-text-field
                                                            v-model="
                                                                step.end_time
                                                            "
                                                            style="width: 75px"
                                                            variant="outlined"
                                                            hide-details
                                                            readonly
                                                            density="compact"
                                                        ></v-text-field>
                                                    </template>
                                                </VueDatePicker>
                                                <!-- <v-text-field
                                                    style="width: 75px"
                                                    v-model="step.end_time"
                                                    :active="step.menu.end_time"
                                                    readonly
                                                    variant="outlined"
                                                    hide-details
                                                    density="compact"
                                                >
                                                    <v-menu
                                                        v-model="
                                                            step.menu.end_time
                                                        "
                                                        :close-on-content-click="
                                                            false
                                                        "
                                                        activator="parent"
                                                        transition="scale-transition"
                                                    >
                                                        <v-time-picker
                                                            v-if="
                                                                step.menu
                                                                    .end_time
                                                            "
                                                            v-model="
                                                                step.end_time
                                                            "
                                                            full-width
                                                            format="24hr"
                                                        ></v-time-picker>
                                                    </v-menu>
                                                </v-text-field> -->
                                                <v-checkbox
                                                    :disabled="l != 0"
                                                    width="90px"
                                                    color="danger"
                                                    v-model="
                                                        timeSlotDay.traffic
                                                    "
                                                    :label="
                                                        timeSlotDay.traffic
                                                            ? 'No traffic'
                                                            : 'Traffic'
                                                    "
                                                    hide-details
                                                ></v-checkbox>
                                            </div>
                                            <div
                                                v-if="
                                                    isStartBiggerThanEnd(
                                                        step.start_time,
                                                        step.end_time,
                                                    )
                                                "
                                            >
                                                <span
                                                    class="text-caption text-danger"
                                                    >Start time should be
                                                    smaller</span
                                                >
                                            </div>
                                            <v-divider
                                                class="my-2"
                                                v-if="
                                                    timeSlotDay.steps.length - 1
                                                "
                                            ></v-divider>
                                        </div>
                                        <div
                                            v-if="checkOverlap(timeSlotDay)"
                                            class="text-caption position-absolute top-0 left-0 text-white bg-danger p-2"
                                        >
                                            <v-icon color="white"
                                                >mdi-alert</v-icon
                                            >
                                            <span
                                                >Time slots are
                                                overlapping</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="timeSlotError"
                            class="text-danger d-flex align-center gap-1"
                        >
                            <v-icon color="danger">mdi-alert</v-icon>
                            {{ timeSlotError }}
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="closeTimeSlotModal" color="primary">
                            Cancel
                        </v-btn>
                        <v-btn
                            @click="submitForm"
                            prepend-icon="mdi-check"
                            color="primary"
                            :disabled="!isValidTimeSlot"
                        >
                            Apply Timeslot
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <div class="statusDiv mb-4">
            <StandardBtn
                v-if="hasPermission('ADVERTISER_OPENING_HOURS_CREATE')"
                type="create"
                @click="addRecord"
            />
        </div>
        <Vue3Datatable
            @pageChange="handlePageChange"
            @pageSizeChange="handlePageSizeChange"
            :paginationInfo="
                'Showing {0} to {1} of {2} entries' +
                ` | Page ${page} of ${Math.ceil(openingHourData.length / pageSize)}`
            "
            :stickyHeader="true"
            :columns="cols"
            :rows="openingHourData"
            :sortable="true"
            :loading="loading"
            skin="bh-table-bordered bh-table-responsive"
        >
            <template #country_ids="{ value }">
                <span
                    class="mr-1"
                    v-for="(id, index) in value?.country_ids"
                    :key="id"
                >
                    <CountryInfo :id="id" />
                    <span v-if="index < value.country_ids?.length - 1">
                        &nbsp;,&nbsp;&nbsp;</span
                    >
                </span>
            </template>
            <template #timezone_id="{ value }">
                <TimeZoneInfo :id="value.timezone_id" />
            </template>
            <template #days="{ value }">
                <OpeningHoursInfo :days="value.days" />
            </template>
            <template #actions="{ value }">
                <div class="d-flex gap-2">
                    <StandardBtn
                        v-if="hasPermission('ADVERTISER_OPENING_HOURS_EDIT')"
                        type="edit"
                        @click="editRecord(value)"
                    />
                    <StandardBtn
                        v-if="hasPermission('ADVERTISER_OPENING_HOURS_DELETE')"
                        type="delete"
                        @click="deleteRecords(value)"
                    />
                </div>
            </template>
        </Vue3Datatable>
    </div>
</template>
<script setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import Swal from "sweetalert2";
import { ref, computed, defineProps, watch, onMounted, defineEmits } from "vue";
import {
    createOpeningHours,
    updateOpeningHours,
    deleteOpeningHours,
    fetchTimeZones,
} from "@/components/advertisers/service.js";
import { useRoute } from "vue-router";
const Route = useRoute();
import TimeZoneCom from "@/components/global/autoComplete/timeZoneCom.vue";
import CountryCom from "@/components/global/autoComplete/countryCom.vue";
import { usePermissions } from "@/composables/usePermissions";
const { hasPermission } = usePermissions();
const emit = defineEmits(["reloadApi"]);
const pageSize = ref(10);
const page = ref(1);
function handlePageChange(value) {
    page.value = value;
}
function handlePageSizeChange(value) {
    pageSize.value = value;
}
const cols = ref([
    // { field: "", title: "ID", width: "90px", filter: false },
    { field: "country_ids", title: "Country" },
    { field: "timezone_id", title: "Timezone" },
    { field: "days", title: "Week Days" },
    { field: "actions", title: "Actions", width: "150px", filter: false },
]);
function checkOverlap(day) {
    return day.steps?.some((step, index) => {
        return day.steps?.some((nextStep, nextIndex) => {
            if (index !== nextIndex) {
                return isTimeOverlap(
                    step.start_time,
                    step.end_time,
                    nextStep.start_time,
                    nextStep.end_time,
                );
            }
            return false;
        });
    });
}
function timeToMinutes(time) {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
}
function isStartBiggerThanEnd(start, end) {
    return timeToMinutes(start) > timeToMinutes(end);
}
function isTimeOverlap(start1, end1, start2, end2) {
    const start1Time = timeToMinutes(start1);
    const end1Time = timeToMinutes(end1);
    const start2Time = timeToMinutes(start2);
    const end2Time = timeToMinutes(end2);
    return start1Time < end2Time && end1Time > start2Time;
}
const timeSlots = ref([
    {
        days: [
            {
                id: 1,
                traffic: false,
                checked: true,
                label: "Monday",
                steps: [
                    {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: "00:00",
                        end_time: "23:59",
                    },
                ],
            },
            {
                id: 2,
                traffic: false,
                checked: true,
                label: "Tuesday",
                steps: [
                    {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: "00:00",
                        end_time: "23:59",
                    },
                ],
            },
            {
                id: 3,
                traffic: false,
                checked: true,
                label: "Wednesday",
                steps: [
                    {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: "00:00",
                        end_time: "23:59",
                    },
                ],
            },
            {
                id: 4,
                traffic: false,
                checked: true,
                label: "Thursday",
                steps: [
                    {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: "00:00",
                        end_time: "23:59",
                    },
                ],
            },
            {
                id: 5,
                traffic: false,
                checked: true,
                label: "Friday",
                steps: [
                    {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: "00:00",
                        end_time: "23:59",
                    },
                ],
            },
            {
                id: 6,
                traffic: false,
                checked: true,
                label: "Saturday",
                steps: [
                    {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: "00:00",
                        end_time: "23:59",
                    },
                ],
            },
            {
                id: 7,
                traffic: false,
                checked: true,
                label: "Sunday",
                steps: [
                    {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: "00:00",
                        end_time: "23:59",
                    },
                ],
            },
        ],
    },
]);
const timeZones = ref([]);
const timeSlotIndex = ref(0);
const timeSlotsPreviousState = ref(null);
const timeSlotError = ref(null);
function addStep(timeSlotDay) {
    timeSlotDay.steps.push({
        menu: {
            start_time: false,
            end_time: false,
        },
        start_time: "00:00",
        end_time: "23:59",
    });
}
const isValidTimeSlot = computed(() => {
    const selectedTimeSlot = timeSlots.value?.[timeSlotIndex.value];
    if (
        selectedTimeSlot?.name === "" ||
        !selectedTimeSlot?.name ||
        selectedTimeSlot?.name.trim() === ""
    ) {
        return false;
    }
    for (let i = 0; i < selectedTimeSlot?.days.length; i++) {
        const day = selectedTimeSlot?.days[i];
        if (checkOverlap(day)) {
            console.log("overlap");
            return false;
        }
        for (let j = 0; j < day.steps.length; j++) {
            const step = day.steps[j];
            if (isStartBiggerThanEnd(step.start_time, step.end_time)) {
                console.log("start bigger than end");
                return false;
            }
        }
    }
    return true;
});
function closeTimeSlotModal() {
    dialog.value = false;
    timeSlots.value = JSON.parse(JSON.stringify(timeSlotsPreviousState.value));
}
function copyStepsToAll(timeSlotDay, index) {
    timeSlots.value[index].days.forEach((day) => {
        day.steps = JSON.parse(
            JSON.stringify(
                timeSlotDay.steps.map((step) => {
                    return {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: step.start_time,
                        end_time: step.end_time,
                    };
                }),
            ),
        );
    });
}
function copyPreviousStep(timeSlotDay, timeSlotIndex, dayIndex) {
    const previousSteps =
        timeSlots.value?.[timeSlotIndex]?.days[dayIndex]?.steps;
    timeSlotDay.steps = JSON.parse(
        JSON.stringify(
            previousSteps.map((step) => {
                return {
                    menu: {
                        start_time: false,
                        end_time: false,
                    },
                    start_time: step.start_time,
                    end_time: step.end_time,
                };
            }),
        ),
    );
}
function removeStep(timeSlotDay, index) {
    timeSlotDay.steps.splice(index, 1);
}
onMounted(async () => {
    timeZones.value = await fetchTimeZones();
});
const Props = defineProps({
    openingHour: {
        type: [Object, Array],
        required: true,
        default: () => [],
    },
});
const openingHourData = ref([]);
watch(
    () => Props.openingHour,
    (newVal) => {
        openingHourData.value = newVal;
    },
    { immediate: true },
);
const openingHourRef = ref(null);
const formType = ref("edit");
const form = ref({
    country_ids: [],
    timezone_id: null,
});
const dialog = ref(false);
const loading = ref(false);
async function submitForm() {
    try {
        const { valid } = await openingHourRef.value.validate();
        if (!valid) {
            return;
        }
        loading.value = true;
        form.value.advertiser_id = Route.params.id;
        // Remove the "menu" object from each step in the days array
        form.value.days = timeSlots.value[0].days.map((day) => ({
            ...day,
            steps: day.steps.map((step) => ({
                start_time: step.start_time,
                end_time: step.end_time,
            })),
        }));
        if (formType.value == "edit") {
            const data = await updateOpeningHours(form.value);
            if (data) {
                emit("reloadApi");
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Opening hours updated successfully.",
                });
            } else {
                throw new Error("UpdateError");
            }
        } else {
            const data = await createOpeningHours(form.value);
            if (data) {
                emit("reloadApi");
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Opening hours created successfully.",
                });
            } else {
                throw new Error("CreateError");
            }
        }
        dialog.value = false;
    } catch (error) {
        console.log(error);

        if (error.message == "UpdateError") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to update opening hours. Please try again.",
            });
        } else if (error.message == "CreateError") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to create opening hours. Please try again.",
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Something went wrong. Please try again.",
            });
        }
        console.error("Failed to submit form:", error);
    } finally {
        loading.value = false;
    }
}
// async function submitForm() {
//     try {
//         const { valid } = await openingHourRef.value.validate();
//         if (!valid) {
//             return;
//         }
//         loading.value = true;
//         form.value.advertiser_id = Route.params.id;
//         form.value.days = timeSlots.value[0].days;
//         if (formType.value == "edit") {
//             await updateOpeningHours(form.value);
//         } else {
//             await createOpeningHours(form.value);
//         }
//         dialog.value = false;
//     } catch (error) {
//         console.error("Failed to submit form:", error);
//     } finally {
//         loading.value = false;
//     }
// }
function deleteRecords(value) {
    console.log(value);
    Swal.fire({
        title: "Are you sure?",
        text: `You are sure about to delete`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                const data = await deleteOpeningHours({
                    advertiser_id: value.advertiser_id,
                    country_id: value.country_ids[0],
                });
                if (data) {
                    emit("reloadApi");
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Record deleted successfully.",
                    });

                    // openingHourData.value = openingHourData.value.filter(
                    //     (row) => row.id !== id,
                    // );
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to delete. Please try again.",
                });
                console.error("Failed to delete:", error);
            }
        }
    });
}
const editRecord = (record) => {
    formType.value = "edit";
    console.log("Edit Record:", record);
    form.value.country_ids = [...record.country_ids];
    form.value.timezone_id = record.timezone_id;
    timeSlots.value = [
        {
            name: "Default",
            days: JSON.parse(JSON.stringify(record.days)),
        },
    ];
    dialog.value = true;
};
const addRecord = async () => {
    formType.value = "add";
    form.value.country_ids = [];
    timeSlots.value = [
        {
            name: "Default",
            days: [
                {
                    id: 1,
                    traffic: false,
                    checked: true,
                    label: "Monday",
                    steps: [
                        {
                            // menu: {
                            //     start_time: false,
                            //     end_time: false,
                            // },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 2,
                    traffic: false,
                    checked: true,
                    label: "Tuesday",
                    steps: [
                        {
                            // menu: {
                            //     start_time: false,
                            //     end_time: false,
                            // },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 3,
                    traffic: false,
                    checked: true,
                    label: "Wednesday",
                    steps: [
                        {
                            // menu: {
                            //     start_time: false,
                            //     end_time: false,
                            // },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 4,
                    traffic: false,
                    checked: true,
                    label: "Thursday",
                    steps: [
                        {
                            // menu: {
                            //     start_time: false,
                            //     end_time: false,
                            // },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 5,
                    traffic: false,
                    checked: true,
                    label: "Friday",
                    steps: [
                        {
                            // menu: {
                            //     start_time: false,
                            //     end_time: false,
                            // },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 6,
                    traffic: false,
                    checked: true,
                    label: "Saturday",
                    steps: [
                        {
                            // menu: {
                            //     start_time: false,
                            //     end_time: false,
                            // },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 7,
                    traffic: false,
                    checked: true,
                    label: "Sunday",
                    steps: [
                        {
                            // menu: {
                            //     start_time: false,
                            //     end_time: false,
                            // },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
            ],
        },
    ];
    dialog.value = true;
};
</script>
<style scoped>
::v-deep .dp__main {
    width: auto;
}
</style>
