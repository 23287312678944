<template>
    <div class="container mt-3">
        <div class="col-md-12">
            <div class="mt-4 mb-4 pb-2 pt-5">
                <nav aria-label="breadcrumb">
                    <ol class="box-shadow breadcrumb bg-light p-4">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'DashboardView' }"
                                >Home</router-link
                            >
                        </li>
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'AdvertisersView' }"
                                >Advertisers</router-link
                            >
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Create Advertiser
                        </li>
                    </ol>
                </nav>
            </div>
            <!-- Tabs Navigation -->
            <ul class="nav nav-tabs mb-4">
                <li class="nav-item">
                    <a class="nav-link active" data-bs-toggle="tab" href="#tab1"
                        >Tab 1</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab2"
                        >Tab 2</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab3"
                        >Tab 3</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab4"
                        >Tab 4</a
                    >
                </li>
            </ul>

            <div class="tab-content">
                <div id="tab1" class="tab-pane fade show active">
                    <!-- Form for Tab 1 -->
                    <div class="box-shadow p-4">
                        <form @submit.prevent="submitForm">
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label
                                        for="advertiser-name"
                                        class="form-label"
                                        >Advertiser Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="advertiser-name"
                                        v-model="form.advertiserName"
                                        required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="display-name" class="form-label"
                                        >Display Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="display-name"
                                        v-model="form.displayName"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label for="manager" class="form-label"
                                        >Manager *</label
                                    >
                                    <select
                                        class="form-select"
                                        id="manager"
                                        v-model="form.manager"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Manager...
                                        </option>
                                        <option
                                            v-for="manager in managers"
                                            :key="manager"
                                            :value="manager"
                                        >
                                            {{ manager }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="status" class="form-label"
                                        >Status *</label
                                    >
                                    <select
                                        class="form-select"
                                        id="status"
                                        v-model="form.status"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Status...
                                        </option>
                                        <option value="active">Active</option>
                                        <option value="inactive">
                                            Inactive
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id="is-test-advertiser"
                                            v-model="form.isTestAdvertiser"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="is-test-advertiser"
                                        >
                                            Is Test Advertiser
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <button
                                        type="submit"
                                        class="btn btn-primary mr-2"
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-secondary"
                                        @click="resetForm"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div id="tab2" class="tab-pane fade box-shadow">
                    <!-- Form sfor Tab 2 -->
                    <div class="p-4">
                        <form @submit.prevent="submitForm">
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label
                                        for="advertiser-name"
                                        class="form-label"
                                        >Advertiser Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="advertiser-name"
                                        v-model="form.advertiserName"
                                        required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="display-name" class="form-label"
                                        >Display Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="display-name"
                                        v-model="form.displayName"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label for="manager" class="form-label"
                                        >Manager *</label
                                    >
                                    <select
                                        class="form-select"
                                        id="manager"
                                        v-model="form.manager"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Manager...
                                        </option>
                                        <option
                                            v-for="manager in managers"
                                            :key="manager"
                                            :value="manager"
                                        >
                                            {{ manager }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="status" class="form-label"
                                        >Status *</label
                                    >
                                    <select
                                        class="form-select"
                                        id="status"
                                        v-model="form.status"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Status...
                                        </option>
                                        <option value="active">Active</option>
                                        <option value="inactive">
                                            Inactive
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id="is-test-advertiser"
                                            v-model="form.isTestAdvertiser"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="is-test-advertiser"
                                        >
                                            Is Test Advertiser
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <button
                                        type="submit"
                                        class="btn btn-primary mr-2"
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-secondary"
                                        @click="resetForm"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div id="tab3" class="tab-pane fade box-shadow">
                    <!-- Form for Tab 3 -->
                    <div class="p-4">
                        <form @submit.prevent="submitForm">
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label
                                        for="advertiser-name"
                                        class="form-label"
                                        >Advertiser Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="advertiser-name"
                                        v-model="form.advertiserName"
                                        required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="display-name" class="form-label"
                                        >Display Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="display-name"
                                        v-model="form.displayName"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label for="manager" class="form-label"
                                        >Manager *</label
                                    >
                                    <select
                                        class="form-select"
                                        id="manager"
                                        v-model="form.manager"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Manager...
                                        </option>
                                        <option
                                            v-for="manager in managers"
                                            :key="manager"
                                            :value="manager"
                                        >
                                            {{ manager }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="status" class="form-label"
                                        >Status *</label
                                    >
                                    <select
                                        class="form-select"
                                        id="status"
                                        v-model="form.status"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Status...
                                        </option>
                                        <option value="active">Active</option>
                                        <option value="inactive">
                                            Inactive
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id="is-test-advertiser"
                                            v-model="form.isTestAdvertiser"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="is-test-advertiser"
                                        >
                                            Is Test Advertiser
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <button
                                        type="submit"
                                        class="btn btn-primary mr-2"
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-secondary"
                                        @click="resetForm"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div id="tab4" class="tab-pane fade box-shadow">
                    <!-- Form for Tab 4 -->
                    <div class="p-4">
                        <form @submit.prevent="submitForm">
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label
                                        for="advertiser-name"
                                        class="form-label"
                                        >Advertiser Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="advertiser-name"
                                        v-model="form.advertiserName"
                                        required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="display-name" class="form-label"
                                        >Display Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="display-name"
                                        v-model="form.displayName"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label for="manager" class="form-label"
                                        >Manager *</label
                                    >
                                    <select
                                        class="form-select"
                                        id="manager"
                                        v-model="form.manager"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Manager...
                                        </option>
                                        <option
                                            v-for="manager in managers"
                                            :key="manager"
                                            :value="manager"
                                        >
                                            {{ manager }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="status" class="form-label"
                                        >Status *</label
                                    >
                                    <select
                                        class="form-select"
                                        id="status"
                                        v-model="form.status"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Status...
                                        </option>
                                        <option value="active">Active</option>
                                        <option value="inactive">
                                            Inactive
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id="is-test-advertiser"
                                            v-model="form.isTestAdvertiser"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="is-test-advertiser"
                                        >
                                            Is Test Advertiser
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <button
                                        type="submit"
                                        class="btn btn-primary mr-2"
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-secondary"
                                        @click="resetForm"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CreateComponent",
    data() {
        return {
            form: {
                advertiserName: "",
                displayName: "",
                manager: "",
                status: "",
                isTestAdvertiser: false,
            },
            managers: ["Manager 1", "Manager 2", "Manager 3"],
        };
    },
    methods: {
        submitForm() {
            console.log("Form Data:", this.form);
            // Handle form submission logic here
        },
        resetForm() {
            this.form = {
                advertiserName: "",
                displayName: "",
                manager: "",
                status: "",
                isTestAdvertiser: false,
            };
        },
    },
};
</script>

<style scoped>
/* Add your styles here */
</style>
