// src/router.js
import { createRouter, createWebHistory } from "vue-router";
import LeadsView from "./views/LeadsView.vue";
import PendingConversionView from "./views/PendingConversionView.vue";
import DashboardView from "./views/DashboardView.vue";
import AdvertisersView from "./views/advertisers/AdvertisersView.vue";
import DistributionsView from "./views/DistributionsView.vue";
import PublishersView from "./views/publishers/PublishersView.vue";
import UsersView from "./views/UsersView.vue";
import leadStatusGroupView from "./views/leadStatusGroup/leadStatusGroupView.vue";
// import CreateAdvertiserView from "./views/advertisers/CreateAdvertiserView.vue";
import CreatePublisherView from "./views/publishers/CreatePublisherView.vue";
import SettingsView from "./views/SettingsView.vue";
import CapsView from "./views/CapsView.vue";
import EditAdvertiserView from "./views/advertisers/EditAdvertiserView.vue";
import EditPublisherView from "./views/publishers/EditPublisherView.vue";
import LoginView from "./views/LoginView.vue"; // Import the new Login view
import ReportsComponent from "./components/Reports/ReportsTable.vue";
import ClickReportsComponent from "./views/ClickReportsView.vue";
import OffersView from "./views/OffersView.vue";
import CampaignsView from "./views/CampaignsView.vue";
import RolesView from "./views/RolesView.vue";

import { useStore } from "vuex";
import { usePermissions } from "@/composables/usePermissions";
const routes = [
    {
        path: "/caps",
        name: "CapsView",
        component: CapsView,
        meta: {
            requiresAuth: true,
            permission: "CAPS_LIST",
        },
    },
    {
        path: "/reports",
        name: "ReportsView",
        component: ReportsComponent,
        meta: {
            requiresAuth: true,
            permission: "REPORTS_LIST",
        },
    },
    {
        path: "/offers",
        name: "OffersView",
        component: OffersView,
        meta: {
            requiresAuth: true,
            permission: "OFFERS_LIST",
        },
    },
    {
        path: "/roles",
        name: "RolesView",
        component: RolesView,
        meta: {
            requiresAuth: true,
            permission: "ROLES_LIST",
        },
    },
    {
        path: "/campaigns",
        name: "CampaignsView",
        component: CampaignsView,
        meta: {
            requiresAuth: true,
            permission: "CAMPAIGNS_LIST",
        },
    },
    {
        path: "/click-reports",
        name: "ClickReportsView",
        component: ClickReportsComponent,
        meta: {
            requiresAuth: true,
            permission: "CLICK_REPORTS_LIST",
        },
    },
    {
        path: "/leads",
        name: "LeadsView",
        component: LeadsView,
        meta: {
            requiresAuth: true,
            permission: "LEADS_LIST",
        },
    },
    {
        path: "/pending-conversion",
        name: "PendingConversionView",
        component: PendingConversionView,
        meta: {
            requiresAuth: true,
            permission: "PENDING_CONVERSIONS_LIST",
        },
    },
    {
        path: "/advertisers",
        name: "AdvertisersView",
        component: AdvertisersView,
        meta: {
            requiresAuth: true,
            permission: "ADVERTISERS_LIST",
        },
    },
    // {
    //     path: "/create/advertiser",
    //     name: "CreateAdvertiserView",
    //     component: CreateAdvertiserView,
    //     meta: {
    //         requiresAuth: true,
    //         permission: "ADVERTISERS_CREATE",
    //     },
    // },
    {
        path: "/edit/advertiser/:id", // Use :id for dynamic segment
        name: "AdvertiserEditView",
        component: EditAdvertiserView,
        meta: {
            requiresAuth: true,
            permission: "ADVERTISERS_EDIT",
        },
    },
    {
        path: "/edit/affiliate/:id", // Use :id for dynamic segment
        name: "PublisherEditView",
        component: EditPublisherView,
        meta: {
            requiresAuth: true,
            permission: "PUBLISHERS_EDIT",
        },
    },
    {
        path: "/rotations",
        name: "DistributionsView",
        component: DistributionsView,
        meta: {
            requiresAuth: true,
            permission: "DISTRIBUTIONS_LIST",
        },
    },
    {
        path: "/affiliates",
        name: "PublishersView",
        component: PublishersView,
        meta: {
            requiresAuth: true,
            permission: "PUBLISHERS_LIST",
        },
    },
    {
        path: "/create/affiliates",
        name: "CreatePublisherView",
        component: CreatePublisherView,
        meta: {
            requiresAuth: true,
            permission: "PUBLISHERS_CREATE",
        },
    },
    {
        path: "/users",
        name: "UsersView",
        component: UsersView,
        meta: {
            requiresAuth: true,
            permission: "USERS_LIST",
        },
    },
    {
        path: "/leadStatusGroup",
        name: "leadStatusGroupView",
        component: leadStatusGroupView,
        meta: {
            requiresAuth: true,
            permission: "STATUS_GROUPS_LIST",
        },
    },
    {
        path: "/settings",
        name: "SettingsView",
        component: SettingsView,
        meta: {
            requiresAuth: true,
            permission: "SETTINGS_LIST",
        },
    },
    {
        path: "/",
        name: "DashboardView",
        component: DashboardView,
        meta: {
            requiresAuth: true,
            permission: "DASHBOARD_LIST",
        },
    },
    {
        path: "/login", // Define the path for the login page
        name: "LoginView", // Name of the route
        component: LoginView, // Component to render
        meta: {
            requiresAuth: false,
        },
    },
    // handle 404
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        meta: {
            requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
            next({ path: "/" });
        },
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach(async (to, from, next) => {
    const store = useStore();
    if (to.meta.requiresAuth && !store.getters.getUser?.token) {
        next({ path: "/login" });
    } else if (!to.meta.requiresAuth && store.getters.getUser?.token) {
        next({ path: "/" });
    } else {
        // next();

        // check permission
        if (to.meta.permission) {
            const hasPermission = usePermissions().hasPermission(
                to.meta.permission,
            );
            console.log("from router", to.meta.permission, hasPermission);
            if (!hasPermission) {
                next({ path: "/" });
            }
        }
        next();
    }
});
export default router;
