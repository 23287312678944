<template>
    <div class="">
        <div
            class="dropdown d-flex gap-2 justify-content-between"
            ref="dropdown"
        >
            <button
                class="btn-shadow btn btn-dark btn-sm rounded dense mb-4"
                @click="toggleDropdown"
            >
                Columns
            </button>
            <div
                v-show="dropdownOpen"
                class="dropdown-content"
                style="margin-top: 0px; z-index: 11"
            >
                <label v-for="col in allColumns" :key="col.field">
                    <input
                        type="checkbox"
                        :checked="col.visible"
                        @change="() => toggleColumnVisibility(col)"
                    />
                    {{ col.title }}
                </label>
            </div>
            <div>
                <StandardBtn type="create" variant="flat" :text="true">
                    Add API Offer
                </StandardBtn>
            </div>
        </div>
        <v-data-table
            :fixed-header="true"
            :fixed-footer="true"
            :sticky="true"
            height="60vh"
            color="#f00"
            :headers="visibleColumns"
            :items="rows"
            :loading="loading"
            :items-per-page-options="[10, 15, 30, 50, 100, 200, 500, 1000]"
            v-model="slectedTableRows"
            class="elevation-1"
            v-model:items-per-page="pageSize"
            :hide-default-footer="true"
            @update:itemsPerPage="handlePageSizeChange"
            @update:page="handlePageChange"
        >
            <template v-slot:top="{ pagination, options, updateOptions }">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="ml-5">{{ paginationInfo }}</div>
                    <v-data-table-footer
                        :pagination="pagination"
                        :options="options"
                        @update:options="updateOptions"
                        items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                </div>
            </template>
            <template #[`item.name`]="{ item }">
                {{ !item.name ? "-" : item.name }}
            </template>
            <template #[`item.actions`]="{}">
                <div class="d-flex gap-2">
                    <StandardBtn type="edit"> </StandardBtn>
                    <StandardBtn type="delete"></StandardBtn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
export default {
    setup() {
        const dropdownOpen = ref(false);
        const dropdown = ref(null);
        const rows = ref([
            {
                id: 4593,
                name: "",
                groups: null,
                created_at: "2024-05-10 16:24:35",
                last_updated_at: "2024-05-10 16:24:35",
            },
            {
                id: 873,
                name: "1031",
                groups: null,
                created_at: "2023-06-07 17:04:37",
                last_updated_at: "2023-06-07 17:04:37",
            },
            {
                id: 843,
                name: "1343_https://blastgarden.com",
                groups: null,
                created_at: "2023-06-06 17:54:04",
                last_updated_at: "2023-06-06 17:54:04",
            },
            {
                id: 923,
                name: "1343_https://blastgarden.com/",
                groups: null,
                created_at: "2023-06-13 17:07:06",
                last_updated_at: "2023-06-13 17:07:06",
            },
            {
                id: 5003,
                name: "16",
                groups: null,
                created_at: "2024-05-16 18:12:58",
                last_updated_at: "2024-05-16 18:12:58",
            },
            {
                id: 4503,
                name: "1cc2ltemfpa",
                groups: null,
                created_at: "2024-05-10 16:08:07",
                last_updated_at: "2024-05-10 16:08:07",
            },
        ]);
        const allColumns = ref([
            {
                value: "id",
                title: "ID",
                visible: true,
                sortable: true,
            },
            {
                value: "name",
                title: "Name",
                visible: true,
                sortable: true,
            },
            {
                value: "groups",
                title: "Groups",
                visible: true,
                sortable: true,
            },
            {
                value: "created_at",
                title: "Created At",
                visible: true,
                sortable: true,
            },
            {
                value: "last_updated_at",
                title: "Last Updated At",
                visible: true,
                sortable: true,
            },
            {
                value: "actions",
                title: "Actions",
                visible: true,
                sortable: true,
            },
        ]);

        const pageSize = ref(100);
        const page = ref(1);
        function handlePageChange(value) {
            page.value = value;
        }
        function handlePageSizeChange(value) {
            pageSize.value = value;
        }
        const paginationInfo = computed(() => {
            if (Math.ceil(rows.value.length / pageSize.value) === 0) {
                return "";
            } else if (pageSize.value === -1) {
                return `Page 1 of 1`;
            } else {
                return `Page ${page.value} of ${Math.ceil(rows.value.length / pageSize.value)}`;
            }
        });

        function toggleColumnVisibility(field) {
            const column = allColumns.value.find(
                (col) => col.value === field.value,
            );
            if (column) {
                column.visible = !column.visible;
            }
        }

        const visibleColumns = computed(() => {
            return allColumns.value.filter((col) => col.visible);
        });
        function toggleDropdown() {
            dropdownOpen.value = !dropdownOpen.value;
        }
        function handleClickOutside(event) {
            if (dropdown.value && !dropdown.value.contains(event.target)) {
                dropdownOpen.value = false;
            }
        }
        onMounted(async () => {
            document.addEventListener("click", handleClickOutside);
        });
        onBeforeUnmount(() => {
            document.removeEventListener("click", handleClickOutside);
        });
        return {
            allColumns,
            visibleColumns,
            toggleColumnVisibility,
            dropdownOpen,
            dropdown,
            toggleDropdown,
            rows,
            pageSize,
            page,
            handlePageChange,
            handlePageSizeChange,
            paginationInfo,
        };
    },
};
</script>

<style scoped>
::v-deep thead > tr,
::v-deep th {
    background: black !important;
    color: white;
}
</style>
